import React from "react";

// import style sheet
import "./latestnews.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import SmartSearchNews from "../../assets/images/smartsearchnews.jpg";
import DataVer2 from "../../assets/images/datav2front.jpg";
import OGNPImage from "../../assets/images/ognportfoliofrontpage.jpg";
import Ratings from "../../assets/images/RATINGS.jpg";
import StarRatingIcon from "../../assets/images/StarRating.jpg";
import TomMooreCharity from "../../assets/images/TomMooreCharityInvestigate.jpg";

//const LatestNews = () => {
  class LatestNews extends React.Component {
    render() {
  return (
    <Container>
      <div className="latest-news-wrapper">
        <div className="latest-news-content">
          <h2>Latest news</h2>
          <div className="news-articles">
            <div className="article">
            <img src={TomMooreCharity} className="img-fluid" alt="article-img" />
              <a href="https://www.theguardian.com/society/2022/jun/30/family-of-captain-sir-tom-moore-face-formal-inquiry-into-charity-setup" target="_blank">
              <h5>Captain Sir Tom Moore foundation to face formal inquiry</h5></a>
              <p>
              Charity Commission to investigate concerns family personally profited from charity set up in veteran’s name
              </p>
            </div>
            <div className="article">
            <img src={StarRatingIcon} className="img-fluid" alt="article-img" />
              <a href="/starrating">
              <h5>Star Ratings Introduced</h5></a>
              <p>
              Star Ratings introduced and search accuracy boosted
              </p>
            </div>
            <div className="article">
              <img src={OGNPImage} className="img-fluid" alt="article-img" />
              <a href="/ognportfolionews">
              <h5>OGN Portfolio Launched</h5></a>
              <p>
              Want to spread your donations wisely? Welcome to the next generation of philanthropy
              </p>
            </div>
            <div className="article">
              <img src={DataVer2} className="img-fluid" alt="article-img" />
              <a href="/datav2" target="_blank">
              <h5>OGN Data Just Got Serious</h5></a>
              <p>
              Better data. Better decisions. More impact 
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
    
  );
};
  }

export default LatestNews;
