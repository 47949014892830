import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Tooltip from "react-bootstrap/Tooltip";

// import style sheet
import "./charitybondresults.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import images
import Banner from "../../assets/images/ognresultbanner.jpg";
import RetrievingResults from "../../assets/images/retrievingresults.png";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";
import StarRating from "../../assets/images/causeicons/Gold Star.png";

import {targetHost} from "../../utils/constants";
import {targetProtocol} from "../../utils/constants";
import {apiUsername} from "../../utils/constants";
import {apiPassword} from "../../utils/constants";


class CharityBondResults extends React.Component {

  state = {
    searchRegion: "",
    searchLocation: "",
    searchUKRegion: "",
    searchLondonBorough: "",
    searchLocalArea: "",
    searchCause: "",
    searchSecondaryCause: "",
    searchMinPerformance: "",
    searchMinReliability: "",
    searchTopX: "",
    searchSortOrder: "",
    searchInputText: "",
    searchBoutique: "",
    searchGiftAid: "",
    searchBasic: true,
    eligibleCharities: [],
    currentCharities: [],
    refinedSortOptions: [],
    currentPage: null,
    totalPages: null,
    organicEntry: true,
    searchResultsPending: false,
    speed: 3,
    searchLocationGranularity:""
  }
  componentDidMount () {
    
    //Check if this request has come from a search page or if entered into URL
    if (this.props.location.state == null) {
      this.setState({organicEntry: false});
    }
    else {

      //Check if this request has come from the basic search or the advanced search
      const { inputBasicSearch } = this.props.location.state;
      this.setState({searchBasic: inputBasicSearch});

      //We want the rendering to wait for the results to come back
      this.setState({searchResultsPending: true});

      const { inputLocation } = this.props.location.state;
      this.setState({searchLocation: inputLocation});
      const { inputRegion } = this.props.location.state;
      this.setState({searchRegion: inputRegion});
      const { inputUKRegion } = this.props.location.state;
      this.setState({searchUKRegion: inputUKRegion});
      const { inputLocalArea } = this.props.location.state;
      this.setState({searchLocalArea: inputLocalArea});
      const { inputLondonBorough } = this.props.location.state;
      this.setState({searchLondonBorough: inputLondonBorough});
      const { inputSearchText } = this.props.location.state;
      this.setState({searchInputText: inputSearchText});
      const { inputCause } = this.props.location.state;
      this.setState({searchCause: inputCause});
      const { inputSecondaryCause } = this.props.location.state;
      this.setState({searchSecondaryCause: inputSecondaryCause});
      const { inputMinPerformance } = this.props.location.state;
      this.setState({searchMinPerformance: inputMinPerformance});
      const { inputMinReliability } = this.props.location.state;
      this.setState({searchMinReliability: inputMinReliability});
      const { inputTopX } = this.props.location.state;
      this.setState({searchTopX: inputTopX});
      const { inputSortOrder } = this.props.location.state;
      this.setState({searchSortOrder: inputSortOrder});
      const { inputGiftAid } = this.props.location.state;
      this.setState({searchGiftAid: inputGiftAid});
      const { inputBoutique } = this.props.location.state;
      this.setState({searchBoutique: inputBoutique});
      const { inputLocationGranularity } = this.props.location.state;
      this.setState({searchLocationGranularity: inputLocationGranularity});

      //Fetch all the charities based on the region and cause
      fetch(
        `${targetProtocol}://${targetHost}/charitybond?username=${apiUsername}&password=${apiPassword}&searchtext=${inputSearchText}&locationgranularity=${inputLocationGranularity}&region=${inputRegion}&location=${inputLocation}&ukregion=${inputUKRegion}&localarea=${inputLocalArea}&londonborough=${inputLondonBorough}&cause=${inputCause}&secondarycause=${inputSecondaryCause}&minperformance=${inputMinPerformance}&minreliability=${inputMinReliability}&topx=${inputTopX}&sortorder=${inputSortOrder}&giftaid=${inputGiftAid}&boutique=${inputBoutique}`
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          let eligibleCharitiesFromApi = data.map((eligibleCharity) => {
            return { charityno: eligibleCharity.charno, charityweb: eligibleCharity.charweb, charityname: eligibleCharity.charname,
                    income: eligibleCharity.income, employees: eligibleCharity.employees, volunteers: eligibleCharity.volunteers,
                    nolocations: eligibleCharity.nolocations, nocauses: eligibleCharity.nocauses, noregions: eligibleCharity.noregions,
                    income_employees: eligibleCharity.income_employees, income_volunteers: eligibleCharity.income_volunteers,
                    num_yrs_finhist: eligibleCharity.num_yrs_finhist, causes_mnemonic : eligibleCharity.causes_mnemonic,
                    pc_restrict: eligibleCharity.pc_restrict, pc_giving : eligibleCharity.pc_giving, pc_income_growth: eligibleCharity.pc_income_growth,
                    pc_activities_growth: eligibleCharity.pc_activities_growth, list_regions: eligibleCharity.list_regions,
                    pc_giving_growth: eligibleCharity.pc_giving_growth, pc_safety: eligibleCharity.pc_safety, last_submitted: eligibleCharity.last_submitted,
                    reliability: eligibleCharity.reliability, performance: eligibleCharity.performance, pc_giving_3yr: eligibleCharity.pc_giving_3yr,
                    rating: eligibleCharity.rating, relevance: eligibleCharity.relevance,
            };  //Sets up locationsFromApi as an object with value and display attributes
          }); 
          this.setState({ 
            eligibleCharities: eligibleCharitiesFromApi
          });
          this.setState({searchResultsPending: false});
        })
        .catch(error => {
          console.log(error);
        });

    } 

  }

  render () {
    
    const { eligibleCharities, currentCharities, currentPage, totalPages } = this.state;
    const totalCharities = eligibleCharities.length;

    //If we have this code then if charities are equal to 0, it renders no output
    //if (this.state.searchResultsPending === true) return null;

    //if (this.state.searchResultsPending === true) return (<img src={RetrievingResults} className="img-fluid" alt="banner" />);
    if (this.state.searchResultsPending === true) return (<div className="loadingimage"><img style={{animation: `spin ${this.state.speed}s linear infinite`}} src={RetrievingResults} width="150px" alt="img"/></div>);

    //Redirect to the homepage if user didnt come through expected channel
    if (this.state.organicEntry === false) return (<Redirect to={{
      pathname: "/"}}>
    </Redirect>);

    //Return this is Charities returned is equal to zero
    if (totalCharities === 0) return (<Fragment>
        <Container>
          <div className="search-result-wrapper">
            <div className="banner">
              <img src={Banner} className="img-fluid" alt="banner" />
            </div>
            <hr className="hr-search"/>
            <Row>
            <Col md={6}>  <br></br><br></br>
            <div className="d-flex flex-row align-items-center">
                <h2 className="h2covert">
                  <strong>{totalCharities}</strong> Charities Found
                </h2>
            </div>
            <br></br><br></br>
            </Col>
            </Row>
          </div>{" "}
            <hr className="hr-search"/>
        </Container>
        <div className="mini-logo-container">
          <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
        </div>
        <Footer />
      </Fragment>
    );

  return (<Fragment>
      <Container>
        <div className="search-result-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-search"/>
          <Row>
          <Col md={6}>  
          <div className="d-flex flex-row align-items-center">
              <h2 className="h2covert">
                Your Portfolio Contains <strong>{totalCharities}</strong> {totalCharities===1?'Charity':'Charities'}
              </h2>
            </div>
          </Col>
          <Col md={6}>
          </Col>
          </Row>
          <div className="search-result-content">
            {eligibleCharities.map((item, index) => {
              return (
                <Fragment>
                  <div className="result" key={index}>
                    <div className="charity-details">
                    <Row>
                      <Col md={6}>
                      <h5>{item.charityname} ({item.charityno})</h5><br></br>
                            <br></br>
                            { parseInt(item.rating,10) > 0 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 20 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 40 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 60 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 80 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                      <br></br><br></br>
                      </Col>
                      <Col md={3}>
                      <h5><a href={item.charityweb} target="_blank">Go to Website</a></h5><br></br><br></br>
                      </Col>
                      <Col md={3}>
                      <div className="details-end-btn">
                      
                      <Link to={`/charity-detail/${item.charityno}`}
                      >
                      <button type="button" className="details-end-btn">Read More</button>
                      </Link>
                      </div>
                      </Col>
                      </Row>
                    </div>
                  </div>
                  {index === (eligibleCharities.length-1) &&
                  <div className="result">
                    <div className="charity-details">
                      <h5>If you would like your portfolio to be managed, so your donation has maximum impact even when the data changes, please <a href="mailto: contactus@opengivingnow.com">register your interest!</a></h5>
                    </div>
                  </div>
                  }
                </Fragment>
              );
            })}
          </div>
        </div>{" "}
          <hr className="hr-search"/>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );

};
}

export default CharityBondResults;
