import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/ratingbanner.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";


class OGNRatings extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>OGN Ratings Explained</h1><br></br><br></br>
            <h5>Monday 08 March 2021</h5><br></br><br></br>
            <p>Here at <b>Open Giving Now</b>, even though all charities listed are subject to stringent checks before inclusion, in keeping with our donor led approach, 
              we feel that it's important to distil our domain expertise and industry research into something which helps donors, while being fair to charities. 
              After careful consideration, we took inspiration from the world of investments, and split our rating into two constituent parts: 
              Performance, and Financial Stability.
            <br></br><br></br>
              <b>Financial Stability </b>looks at measures such as how long a charity has been operating for, 
              gaps in accounting, reserves policy, and any potential volatility in the income streams. This is effectively a measure which tells a donor that it 
              is safe to invest in the charity and know that the money will be spent as planned, on the advertised causes. It's measured against 
              absolute targets, i.e. a good reserves policy should ideally cover the charity's expenditure for 6 months
              <br></br><br></br>       
              To calculate the stability, we used the following measures:
              <br></br><br></br>              
              1) Credibility check: How many years of financial history are there? 
              <br></br>
              2) Transparency check: Since accounts were first submitted, how often have accounts not been submitted? (in the last 5 yrs)
              <br></br>             
              3) Ongoing Income: How much of a reliance is there on one-off payments such as endowments and legacies?
              <br></br>
              4) Reliability of Income and Planning: What is the 5 year average of the difference between income and spending?
              <br></br>
              5) Security: How many months will reserves cover the charity's spending?       
              <br></br><br></br>
              <b>Performance </b>looks at two key aspects of a charity; income generation, and how much of that is spent on charitable activities. 
              These aspects are looked at from two vantage points; the last year that results were posted, and also the change since the year before. This helps us understand how a charity is performing, 
              and what direction it's headed. To measure, we analysed the figures of all the listed charities, 
              pulling out the lower and upper quartiles along with the median, to create a benchmark to measure the selected charity against. 
              Here we are effectively saying, given the current conditions in the industry, how is this charity performing when compared to its peers?
              <br></br><br></br>
              To calculate the efficiency we have used the following measures:
              <br></br><br></br>              
              1) Income per Employee: How much revenue does each employee generate?
              <br></br>
              2) Giving Efficiency: How much of the total income goes on charitable causes? 
              <br></br>            
              3) Income Growth: Using the last two years of accounts, did income grow or contract?
              <br></br>
              4) Giving Efficiency Growth: Using the last two years of accounts, was the charity more efficient in giving?
              <br></br><br></br>
              <b>Open Giving Now </b>will continually improve the constituent parts of these ratings in line with the latest industry expertise. So all you have 
              to do is decide how much Performance and Financial Stability matters to you, when choosing your perfect charity.
            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default OGNRatings;
