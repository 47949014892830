import React, { Fragment } from "react";
import { Redirect } from "react-router";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { VictoryLine, VictoryChart, VictoryLegend, VictoryContainer } from "victory";

// import style sheet
import "./charitydetail.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import images
import Banner from "../../assets/images/search-result.jpg";
import MiniLogo from "../../assets/images/logo-mini.jpg";
import Footer from "../Footer";
import DisplayCauseIcons from '../SearchResult/displaycauseicons';
import StarRating from "../../assets/images/causeicons/Gold Star.png";

import {targetHost} from "../../utils/constants";
import {targetProtocol} from "../../utils/constants";
import {apiUsername} from "../../utils/constants";
import {apiPassword} from "../../utils/constants";

//const CharityDetail = () => {
class CharityDetail extends React.Component {

  state = {
    drillDownCharity: "",
    searchCause: "",
    organicEntry: true,
    eligibleCharities: [],
    incomeData: [],
    histIncomes: [],
    histExps: [],
    topPoint: null,
    bottomPoint: null
  }
  componentDidMount () {

    //Check if this request has come from a search page or if entered into URL
    if (this.props.match.params == null) {
      this.setState({organicEntry: false});
    }
    else {

      const drilldownchar  = this.props.match.params.charpassno;
      this.setState({drillDownCharity: drilldownchar});

      //Fetch all the charities based on the region and cause
      fetch(
        `${targetProtocol}://${targetHost}/charitydrilldown?username=${apiUsername}&password=${apiPassword}&chardrilldown=${drilldownchar}`
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          let eligibleCharitiesFromApi = data.map((eligibleCharity) => {
            return { charityno: eligibleCharity.charno, charityweb: eligibleCharity.charweb, charityname: eligibleCharity.charname,
              income_total_income_and_endowments: eligibleCharity.income_total_income_and_endowments, count_employees: eligibleCharity.count_employees, count_volunteers: eligibleCharity.count_volunteers,
              nolocations: eligibleCharity.nolocations, nocauses: eligibleCharity.nocauses, noregions: eligibleCharity.noregions,
              income_employees: eligibleCharity.income_employees, income_volunteers: eligibleCharity.income_volunteers,
              income_legacies: eligibleCharity.income_legacies, income_donations_and_legacies: eligibleCharity.income_donations_and_legacies, income_endowments: eligibleCharity.income_endowments,
              income_charitable_activities: eligibleCharity.income_charitable_activities, income_investments: eligibleCharity.income_investments, income_other: eligibleCharity.income_other,
              expenditure_total: eligibleCharity.expenditure_total, expenditure_charitable_expenditure: eligibleCharity.expenditure_charitable_expenditure, expenditure_grants_institution: eligibleCharity.expenditure_grants_institution, expenditure_raising_funds: eligibleCharity.expenditure_raising_funds,
              funds_total: eligibleCharity.funds_total, funds_restricted: eligibleCharity.funds_restricted, funds_unrestricted: eligibleCharity.funds_unrestricted,
              reserves: eligibleCharity.reserves, assets_total_fixed: eligibleCharity.assets_total_fixed, assets_other_assets: eligibleCharity.assets_other_assets, assets_cash: eligibleCharity.assets_cash,
              creditors_one_year_total_current: eligibleCharity.creditors_one_year_total_current, creditors_falling_due_after_one_year: eligibleCharity.creditors_falling_due_after_one_year, running_costs: eligibleCharity.running_costs,
              num_yrs_finhist: eligibleCharity.num_yrs_finhist, causes_mnemonic : eligibleCharity.causes_mnemonic,
              pc_restrict: eligibleCharity.pc_restrict, pc_giving : eligibleCharity.pc_giving, pc_income_growth: eligibleCharity.pc_income_growth,
              pc_activities_growth: eligibleCharity.pc_activities_growth, list_regions: eligibleCharity.list_regions,
              pc_giving_growth: eligibleCharity.pc_giving_growth, pc_safety: eligibleCharity.pc_safety, last_submitted: eligibleCharity.last_submitted,
              reliability: eligibleCharity.reliability, performance: eligibleCharity.performance, pc_giving_3yr: eligibleCharity.pc_giving_3yr,
              incexp_dev_5yr: eligibleCharity.incexp_dev_5yr, num_yearsbroken: eligibleCharity.num_yearsbroken, pc_legend: eligibleCharity.pc_legend,
              charity_activities: eligibleCharity.charity_activities, charity_gift_aid: eligibleCharity.charity_gift_aid, charity_reporting_status: eligibleCharity.charity_reporting_status, 
              charity_professional_fundraiser: eligibleCharity.charity_professional_fundraiser, income_from_government_contracts: eligibleCharity.income_from_government_contracts, income_from_government_grants: eligibleCharity.income_from_government_grants, 
              rating: eligibleCharity.rating, relevance: eligibleCharity.relevance,
            };  //Sets up locationsFromApi as an object with value and display attributes
          }); 
          this.setState({ //Add one at the top which invites you to select a location
            eligibleCharities: eligibleCharitiesFromApi
          });
        })
        .catch(error => {
          console.log(error);
        });

      //Now setup the historical data for the line chart
      fetch(
        `${targetProtocol}://${targetHost}/charityhistory?username=${apiUsername}&password=${apiPassword}&chardrilldown=${drilldownchar}`
      )
        .then(res => {
          return res.json();
        })
        .then(data => {  //Gets all of the data returned
          var histValsFromApi = data.map((histVals) => {
            return { yearend: histVals.fin_period_end_date.substring(0,4), income: histVals.income_total_income_and_endowments, exp: histVals.expenditure_total,
              charact: histVals.expenditure_charitable_expenditure, reserves: histVals.reserves, funds_res: histVals.funds_restricted, 
              funds_total: histVals.funds_total
            };  
          }); 

          var highPoint = numberHigh(histValsFromApi);
          var lowPoint = numberLow(histValsFromApi);
          var midPoint = highPoint-lowPoint;
          highPoint = adjNumber((highPoint+(midPoint*0.2)), midPoint);
          lowPoint = adjNumber((lowPoint-(midPoint*0.1)), midPoint);

          var unitsInc = " (Millions)"
          if (midPoint<=1000000) {
            unitsInc = " (Thousands)"
          }
          
          var histIncomeFromApi = histValsFromApi.map((histIncome) => {  //Extract the income data
            return { x: histIncome.yearend, y: adjNumber(histIncome.income, midPoint)
            };
          });
          var histExpFromApi = histValsFromApi.map((histExp) => {  //Extract the income data
            return { x: histExp.yearend, y: adjNumber(histExp.exp, midPoint)
            };
          });
          this.setState({ //Add one at the top which invites you to select a location
            histIncomes: histIncomeFromApi,
            histExps: histExpFromApi,
            topPoint: highPoint,
            bottomPoint: lowPoint,
            units: unitsInc
          });
        })
    }
  }


  render() {

    const givingPcTooltip = props => (
      <Tooltip {...props}>This is the amount that a charity gives to charitable activities as a percentage of it's total income (in the last 3 years). Click More for additional context</Tooltip>
    );
    const finHistTooltip = props => (
      <Tooltip {...props}>This is the number of years that a charity has submitted detailed financial accounts</Tooltip>
    );
    const fundsResTooltip = props => (
      <Tooltip {...props}>This is the percentage of a charity's funds which are restricted, and cannot be spent freely</Tooltip>
    );
    const deltaIncomeTooltip = props => (
      <Tooltip {...props}>This is the growth in income, taken from the previous 2 year's financial statements</Tooltip>
    );
    const deltaGivingTooltip = props => (
      <Tooltip {...props}>This is the growth in giving efficiency, taken from the previous 2 year's financial statements</Tooltip>
    );
    const endowTooltip = props => (
      <Tooltip {...props}>Donations of money or other financial assets, which often come with caveats stating how much of each year’s income can be spent</Tooltip>
    );
    const volTooltip = props => (
      <Tooltip {...props}>Contributions from the public, often seen as the most beneficial funding due to the non-restricted nature</Tooltip>
    );
    const charActTooltip = props => (
      <Tooltip {...props}>This is income gained as a result of charitable activities, e.g. sales from charity shops or admission fees for fundraising events</Tooltip>
    );
    const investTooltip = props => (
      <Tooltip {...props}>Income as a result of investments made</Tooltip>
    );
    const legaciesTooltip = props => (
      <Tooltip {...props}>These are one off/non-regular investments, e.g. as a result of death, and seen to be volatile source of income on that basis</Tooltip>
    );
    const runningTooltip = props => (
      <Tooltip {...props}>The running costs, calculated from the total of the support and governance costs</Tooltip>
    );
    const expCharActTooltip = props => (
      <Tooltip {...props}>The amount spent on charitable activities, and seen by many to encapsulate the purpose of the charity</Tooltip>
    );
    const grantsTooltip = props => (
      <Tooltip {...props}>This is the amount awarded as grants to others</Tooltip>
    );
    const fundraisingTooltip = props => (
      <Tooltip {...props}>This is the amount that has been spent on fundraising</Tooltip>
    );
    const accYearTooltip = props => (
      <Tooltip {...props}>This is the last submitted accounting year, and the one for which these figures apply (day and month in brackets)</Tooltip>
    );
    const accountingBreaksTooltip = props => (
      <Tooltip {...props}>Since accounts were first submitted, this is the number of years that accounts have not been submitted (last 5 years)</Tooltip>
    );
    const incExpDevTooltip = props => (
      <Tooltip {...props}>This measures the difference between income and spending and relates it to the total income as a percentage (last 5 years)</Tooltip>
    );
    const legendTooltip = props => (
      <Tooltip {...props}>This is the % of total income that is achieved through legacies and endowments</Tooltip>
    );
    const monResTooltip = props => (
      <Tooltip {...props}>This measures the amount of months a charity can continue as normal with no income, based on their reserves and total expenditure</Tooltip>
    );
    const repStatusTooltip = props => (
      <Tooltip {...props}>This describes the status of the submission of the most recent set of accounts to the UK charities commission, including any lateness</Tooltip>
    );

    if (this.state.organicEntry === false) return (<Redirect to={{
      pathname: "/"}}>
    </Redirect>);

    return (
      <Fragment>
        {this.state.eligibleCharities.map((item, index) => {
          return (
        <Container>
          <div className="charity-detail-wrapper">
            <div className="banner">
              <img src={Banner} className="img-fluid" alt="banner" />
            </div>
            <hr className="hr-detail"/>
            <div className="charity-detail-content">
              <div className="charity-detail">
                  <Row>
                    <Col md={6}>
                    <div className="charity-details">
                      <h2>{item.charityname} ({item.charityno})</h2>
                            <br></br>
                            { parseInt(item.rating,10) > 0 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 20 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 40 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 60 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 80 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                      <br></br><br></br>
                          <h3>{item.charity_activities}</h3>
                    </div>
                    </Col>
                    <Col md={6}>
                    <div className="charity-details">
                      <DisplayCauseIcons causes_mnemonic={item.causes_mnemonic} />
                      <br></br>
                      <br></br>
                      <h6 className="pchange">Stability: <b>{numberWithCommas(item.reliability)}% </b></h6><br></br>
                      <h6 className="pchange">Performance: <b>{numberWithCommas(item.performance)}% </b></h6>
                      <h4><a href="/ogn-ratings-overview">How are these calculated?</a></h4><br></br>
                      <h6><a href={item.charityweb} target="_blank">Go to Website</a></h6>
                    </div>
                    </Col>
                  </Row>
              </div>
              <Row>
                <Col md={6}>
                  <div className="detailed-grouping">
                  <fieldset className="fieldset-detailed">
                    <h5>Key Performance Indicators</h5><br></br>
                    <p>Giving Efficiency: <b>{numberWithCommas(item.pc_giving_3yr)}% </b><OverlayTrigger placement="top" overlay={givingPcTooltip}><BsInfoCircle/></OverlayTrigger> <a href="/giving-efficiency">More</a></p>
                    <p>Income per employee: <b>£{numberWithCommas(item.income_employees)}</b></p>
                    <p>Income per volunteer: <b>£{numberWithCommas(item.income_volunteers)}</b></p>
                    <p>Income Growth: <b>{item.num_yrs_finhist <= 1 ? 'N/A' : numberWithCommasPc((item.pc_income_growth-100).toFixed(2))} </b><OverlayTrigger placement="top" overlay={deltaIncomeTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Growth in Giving Efficiency: <b>{item.num_yrs_finhist <= 1 ? 'N/A' : numberWithCommasPc((item.pc_giving_growth-100).toFixed(2))} </b><OverlayTrigger placement="top" overlay={deltaGivingTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Income Volatility: <b>{numberWithCommas(item.pc_legend)}% </b><OverlayTrigger placement="top" overlay={legendTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Income/Spending Deviation: <b>{numberWithCommas(item.incexp_dev_5yr)}% </b><OverlayTrigger placement="top" overlay={incExpDevTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Restricted Funds: <b>{numberWithCommas(item.pc_restrict)}% </b><OverlayTrigger placement="top" overlay={fundsResTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Months of Reserves: <b>{numberWithCommas(item.pc_safety)} </b><OverlayTrigger placement="top" overlay={monResTooltip}><BsInfoCircle/></OverlayTrigger></p>
                </fieldset>
                </div>
                </Col>
                <Col md={6}>
                  <div className="detailed-grouping">
                  <fieldset className="fieldset-detailed">
                    <h5>Vital Statistics</h5><br></br>
                    <p>Accounting Yr: <b>{(item.last_submitted.substring(0,4))-1}-{item.last_submitted.substring(0,4)} ({(item.last_submitted.substring(8,10))}-{(item.last_submitted.substring(5,7))}) </b><OverlayTrigger placement="top" overlay={accYearTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                    <p>Status: <b>{item.charity_reporting_status} </b><OverlayTrigger placement="top" overlay={repStatusTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Financial History: <b>{(item.num_yrs_finhist)} Yrs </b><OverlayTrigger placement="top" overlay={finHistTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                    <p>Accounting Breaks: <b>{(item.num_yearsbroken)} Yrs </b><OverlayTrigger placement="top" overlay={accountingBreaksTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                    <p>Employees: <b>{numberWithCommas(item.count_employees)}</b></p> 
                    <p>Volunteers: <b>{numberWithCommas(item.count_volunteers)}</b></p>
                    <p>No of Supported Areas: <b>{item.nolocations}</b></p> 
                    <p>Supported Regions: <b>{item.list_regions}</b></p>
                    <p>Uses Pro Fundraiser: <b>{item.charity_professional_fundraiser} </b></p>
                    <p>Gift Aid Registered: <b>{item.charity_gift_aid} </b></p>
                </fieldset>
                </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                <div className="detailed-grouping">
                <fieldset className="fieldset-detailed">
                <h5>Income and Spending History{this.state.units}</h5><br></br>
                <VictoryChart
  containerComponent={
    <VictoryContainer
      style={{
        pointerEvents: "auto",
        userSelect: "auto",
        touchAction: "auto"
      }}
    />
  }
>
                    <VictoryLine interpolation="natural" style={{data: { stroke: "#2f527a" }}} domain={{ y: [this.state.topPoint, this.state.bottomPoint] }} data={this.state.histIncomes} />
                    <VictoryLine interpolation="natural" style={{data: { stroke: "#b4e1d8" }}} domain={{ y: [this.state.topPoint, this.state.bottomPoint] }} data={this.state.histExps} />
                    <VictoryLegend x={125} y={50}	centerTitle orientation="horizontal" gutter={20} style={{ border: { stroke: "white" } }}
                      data={[
                        { name: "Income", symbol: { fill: "#2f527a" } },
                        { name: "Expenditure", symbol: { fill: "#b4e1d8" } }
                      ]}
                    />
                  </VictoryChart>
                  </fieldset>
                  </div>
                </Col>
                <Col md={6}>
                <div className="detailed-grouping">
                  <fieldset className="fieldset-detailed">
                    <h5>Income Breakdown</h5><br></br>
                    <p>Total Income: <b>£{numberWithCommas(item.income_total_income_and_endowments)}</b></p> 
                    <p>Endowments: <b>£{numberWithCommas(item.income_endowments)} </b><OverlayTrigger placement="top" overlay={endowTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Voluntary: <b>£{numberWithCommas(item.income_donations_and_legacies)} </b><OverlayTrigger placement="top" overlay={volTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Charitable Activities: <b>£{numberWithCommas(item.income_charitable_activities)} </b><OverlayTrigger placement="top" overlay={charActTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Investments: <b>£{numberWithCommas(item.income_investments)} </b><OverlayTrigger placement="top" overlay={investTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Other Sources: <b>£{numberWithCommas(item.income_other)} </b></p>
                    <p>Legacies (Additional): <b>£{numberWithCommas(item.income_legacies)} </b><OverlayTrigger placement="top" overlay={legaciesTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                    <p>Govt Grants: <b>£{numberWithCommas(item.income_from_government_grants)} </b></p>
                    <p>Govt Contracts: <b>£{numberWithCommas(item.income_from_government_contracts)} </b></p> 
                </fieldset>
                </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="detailed-grouping">
                  <fieldset className="fieldset-detailed">
                    <h5>Spending Breakdown</h5><br></br>
                    <p>Total Spending: <b>£{numberWithCommas(item.expenditure_total)}</b></p>
                    <p>Running Costs: <b>£{numberWithCommas(item.running_costs)} </b><OverlayTrigger placement="top" overlay={runningTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                    <p>Charitable Activities: <b>£{numberWithCommas(item.expenditure_charitable_expenditure)} </b><OverlayTrigger placement="top" overlay={expCharActTooltip}><BsInfoCircle/></OverlayTrigger></p>
                    <p>Grants: <b>£{numberWithCommas(item.expenditure_grants_institution)} </b><OverlayTrigger placement="top" overlay={grantsTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                    <p>Fundraising: <b>£{numberWithCommas(item.expenditure_raising_funds)} </b><OverlayTrigger placement="top" overlay={fundraisingTooltip}><BsInfoCircle/></OverlayTrigger></p> 
                </fieldset>
                </div>
                </Col>
                <Col md={6}>
                <div className="detailed-grouping">
                  <fieldset className="fieldset-detailed">
                    <h5>Detailed Financials</h5><br></br>
                    <p>Restricted Funds: <b>£{numberWithCommas(item.funds_restricted)}</b></p> 
                    <p>Unrestricted Funds: <b>£{numberWithCommas(item.funds_unrestricted)}</b></p>
                    <p>Reserves: <b>£{numberWithCommas(item.reserves)}</b></p>
                    <p>Creditors due in 1 yr: <b>£{numberWithCommas(item.creditors_one_year_total_current)}</b></p>
                    <p>Long Term Creditors: <b>£{numberWithCommas(item.creditors_falling_due_after_one_year)}</b></p>
                    <p>Total Fixed Assets: <b>£{numberWithCommas(item.assets_total_fixed)}</b></p>
                    <p>Current/Other Assets: <b>£{numberWithCommas(item.assets_other_assets)}</b></p> 
                    <p>Cash Assets: <b>£{numberWithCommas(item.assets_cash)}</b></p> 
                </fieldset>
                </div>
                </Col>  
              </Row>
            </div>
          </div>
          <hr className="hr-detail"/>
          
        </Container>
          );
        })}
        <div className="mini-logo-container">
          <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
        </div>
        <Footer />
      </Fragment>
    );
  };
}

function numberWithCommas(x) {
  if (x == null || x === "") {
    x = 0;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithCommasPc(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"%";
}

function numberHigh(histValsArray) { //silly number to be sure to go over

  var highestNum = -10000000000000;

  for (let histIncome of histValsArray) {  //Extract the income data
    if (parseInt(histIncome.income)>highestNum) {
      highestNum = parseInt(histIncome.income);
    }
    if (parseInt(histIncome.exp)>highestNum) {
      highestNum = parseInt(histIncome.exp);
    }
  };
  return highestNum;
}

function numberLow(histValsArray) {

  var lowestNum = 10000000000000; //silly number to be sure to go under

  for (let histIncome of histValsArray) {  //Extract the income data
    if (parseInt(histIncome.income)<lowestNum) {
      lowestNum = parseInt(histIncome.income);
    }
    if (parseInt(histIncome.exp)<lowestNum) {
      lowestNum = parseInt(histIncome.exp);
    }
  };
  return lowestNum;
}

function adjNumber(inputNum, midPoint) {
  if (midPoint > 1000000) {
    return inputNum/1000000;
  }
  else {
    return inputNum/1000
  }
}

export default CharityDetail;
