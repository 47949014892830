export const options = [
  { value: "value1", label: "Value 1" },
  { value: "value2", label: "Value 2" },
  { value: "value3", label: "Value 3" },
];

export const volunteers = [
  { value: "Any", label: "Any" },
  { value: "1000", label: "1,000" },
  { value: "5000", label: "5,000" },
  { value: "10000", label: "10,000" },
  { value: "50000", label: "50,000" },
  { value: "100000", label: "100,000" },
  { value: "500000", label: "500,000" },
];

export const employees = [
  { value: "Any", label: "Any" },
  { value: "1000", label: "1,000" },
  { value: "5000", label: "5,000" },
  { value: "10000", label: "10,000" },
  { value: "50000", label: "50,000" },
];

export const sortOptions = [
  { value: "income desc", label: "Income (High)" },
  { value: "income asc", label: "Income (Low)" },
  { value: "nocauses asc", label: "Cause Focus" },
  { value: "performance desc", label: "Performance" },
  { value: "reliability desc", label: "Stability" },
  { value: "relevance desc", label: "Relevance" },
  { value: "rating desc", label: "Rating" },
];

export const sortpref = [
  { value: "performance desc, reliability desc", label: "Performance" },
  { value: "reliability desc, performance desc", label: "Reliability" },
];

export const mins = [
  { value: "0", label: "0" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "55", label: "55" },
  { value: "60", label: "60" },
  { value: "65", label: "65" },
  { value: "70", label: "70" },
  { value: "75", label: "75" },
  { value: "80", label: "80" },
  { value: "85", label: "85" },
  { value: "90", label: "90" },
  { value: "95", label: "95" },
];

export const topx = [
  { value: "3", label: "3" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "20", label: "20" },
];

export const regionsC = [
  { value: "value1", label: "UK" },
  { value: "value2", label: "Europe" },
  { value: "value3", label: "Africa" },
  { value: "value4", label: "Asia" },
  { value: "value5", label: "North America" },
  { value: "value6", label: "South America" },
  { value: "value7", label: "Oceania" },
  { value: "value8", label: "Antartica" },
];

export const causesC = [
  { value: "Animal Welfare", label: "Animal Welfare" },
  { value: "Arts and Science", label: "Arts and Science" },
  { value: "Childrens Welfare", label: "Childrens Welfare" },
  { value: "Community Development", label: "Community Development" },
  { value: "Disabilities", label: "Disabilities" },
  { value: "Education", label: "Education" },
  { value: "Elderly", label: "Elderly" },
  { value: "Environment and Conservation", label: "Environment and Conservation" },
  { value: "Ethnic Groups", label: "Ethnic Groups" },
  { value: "General", label: "General" },
  { value: "Homelessness", label: "Homelessness" },
  { value: "Human Rights and Diversity", label: "Human Rights and Diversity" },
  { value: "Military/Emergency Services", label: "Military/Emergency Services" },
  { value: "Overseas Aid/Famine Relief", label: "Overseas Aid/Famine Relief" },
  { value: "Physical and Mental Health", label: "Physical and Mental Health" },
  { value: "Poverty Relief", label: "Poverty Relief" },
  { value: "Recreation and Wellness", label: "Recreation and Wellness" },
  { value: "Religion", label: "Religion" },
  { value: "Sports Development", label: "Sports Development" },
];

export const targetHost = "capi.opengivingnow.com";
export const targetProtocol = "https";
//export const targetProtocol = "http";
//export const targetHost = "localhost:3001";
export const apiUsername = "opengivingnow_api";
export const apiPassword = "dn3kn6mlkT6Y7bs8";
