import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// import style sheet
import "./about.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/about-us.jpg";
import MiniLogo from "../../assets/images/logo-mini.jpg";
import Footer from "../Footer";

//const AboutUs = () => {
class AboutUs extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="about-us-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-aboutus"/>
          <div className="about-us-content">
            <h1>About Us</h1><br></br><br></br>
            <p>As we advance further towards a culture which values philanthrophy, many people are asking themselves how they can donate reliably to charitable causes that matter
              to them. With some studies showing that as many as 50% of people are reluctant to donate due to a lack of trust, and regular donors 
              hesitant to increase donations, there is a clear need for trust and transparency between donor and charity
              so that both can prosper. <br></br> <br></br>
              
              <b>Open Giving Now</b> was formed to help people who want to donate to a charity, but need help finding reliable charities that support causes and 
              regions that matter to them. <br></br> <br></br>

              For information on how we achieve this, please go to <Link to={{pathname: "/what-we-do"}}>What We Do</Link> to find out more!
            
            </p>
          </div>
          <hr className="hr-aboutus"/>
          
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default AboutUs;
