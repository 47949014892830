import React, { Fragment } from "react";

// import style sheet
import "./whatwedo.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/what-we-do.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";

//const WhatWeDo = () => {
  class WhatWeDo extends React.Component {
    render() {
  return (
    <Fragment>
      <Container>
        <div className="what-we-do-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-whatwedo"/>
          <div className="what-we-do-content">
            <h1>What We Do</h1><br></br><br></br>
            <p>With some studies showing that as many as 50% of people are reluctant to donate to charities due to a lack of trust, and regular donors 
              hesitant to increase donations, there is a clear need for trust and transparency between donor and charity so that both can prosper. 
              In addition to this, with over 160,000 registered charities in the UK alone, and as people become more discerning in picking the causes and the regions that matter to them, 
              it is ever more difficult for people to find their perfect charity. <b> Open Giving Now</b> was created to provide a direct and trustworthy connection from the donor to their charity.
               <br></br><br></br>
               First, we took all of the charities registered in the UK and filtered them based on their transparency and ability to be effective.
               <br></br><br></br>
               As a minimum, all charities listed provide
               detailed accounts, verified by an accountant, and submitted within a reasonable timeframe (defined by the UK charities commission).
               These accounts provide information such as income, expenditure, reserves, staff, and volunteers, with <b> Open Giving Now </b> 
               further analysing to provide key performance indicators such as solvency, growth, and effectiveness.
               Each listed charity also has a website to provide a clear vision of their purpose, and remain scalable, transparent, and contactable as donations grow. 
               <br></br><br></br>
               We then profiled each charity to understand the causes they support, and the areas they operate in, to make it easy for you
               to find a list of relevant charities, understand where their focus lies, and be sure that they align with
               your philanthropic needs. 
               <br></br><br></br>
               We believe this is the least you deserve when investing your money, so this is done <b>every time</b> the underlying information is updated.
               And for all of the charities listed, more detailed information is just one click away, so you can be sure that
               they are suitable for your investment.
               
               

            </p>
          </div>
      <hr className="hr-supportus"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default WhatWeDo;
