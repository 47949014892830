import React, { Fragment } from "react";

// import style sheet
import "./supportus.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/support-us.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";

//const SupportUs = () => {
class SupportUs extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="support-us-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-supportus"/>
          <div className="support-us-content">
            <h1>Support Us</h1><br></br><br></br>
            <p>Here at <b>Open Giving Now</b> we are always working on ways to improve, investing time and effort 
               so people are quickly matched with their perfect charities. <br></br><br></br>

               We've also got a number of projects in the pipeline to enhance data quality, presentation, and continually improve
               our process of recommending charities.<br></br><br></br>

               If you would like to support us on our journey, we're always grateful to people who promote what we do.
                If you would like to be more involved, by investing time, ideas, or making a donation, we would love to chat, so please <a href="mailto: contactus@opencharitynow.com">Contact Us</a>.

            </p>
          </div>
      <hr className="hr-supportus"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default SupportUs;
