import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/smartsearchbanner.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";


class OGNSmartSearchNews extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>Smart Search Launched</h1><br></br><br></br>
            <h5>Monday 6th September 2021</h5><br></br><br></br>
            <p>Here at<b> Open Giving Now </b>we have always prided ourselves on our ability to hook you up with the best charity for your needs. 
            We did this through a combination of our simple search, and the advanced search for the more discerning donor.
              <br></br><br></br>
              From September onwards, we are delighted to release the new Smart Search, now the default tool, which combines the 
              ease of the simple search, with the precision of the advanced search, wrapped in a great new UI that we think you're going to 
              find pretty amazing.
              <br></br><br></br>              
              Still want more? Once you've been presented with your results, you can now provide a few extra details and quickly create a portfolio 
              of charities!
              <br></br><br></br>
              So what are you waiting for? Why not go take a <a href="/smartsearch">look!</a>?
              <br></br><br></br>
              
            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default OGNSmartSearchNews;
