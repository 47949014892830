import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/support-us.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";

//const SupportUs = () => {
class OGNLaunchStory extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>Welcome to the launch of Open Giving Now!</h1><br></br><br></br>
            <h5>Monday 15 February 2021</h5><br></br><br></br>
            <p>Open Giving Now was formed in February 2021 as a response to the difficulty of finding a reliable charity to give money to. With studies suggesting as many as
               50% of people are still reluctant to donate because of trust, it was clear that a bridge was needed to be built between the willing donor and the worthy cause.<br></br><br></br>
               Here at Open Giving Now, we realised that the reason for this was two fold. Firstly, while there are companies which aid the selection of a charity for a donor, 
               the process of finding one was often long winded and prone to error, and there still wasn't 
               enough information to be sure that a suggested charity could handle the money effectively. Secondly, it needed to be made easier for someone with a passion for 
               particular causes, or for particular locations, to be able to find these charities. Combining both, Open Giving Now was born, allowing donors to drill down into the 
               sectors and locations they care about, sort by the quality metrics that matter to them, and then interrogate the charity to be sure that it is a good fit.<br></br><br></br>
               We think we've done pretty well at making life a bit easier for you, and for people in need of charity worldwide. We hope you think the same!
            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default OGNLaunchStory;
