import React from "react";
import { Route, Switch } from "react-router-dom";

// import components
import Home from "../components/Home";
import NavbarMain from "../components/Navbar";
import AboutUs from "../components/AboutUs";
import NewsFeatures from "../components/NewsFeatures";
import SupportUs from "../components/SupportUs";
import SmartSearch from "../components/SmartSearch";
import WhatWeDo from "../components/WhatWeDo";
import SearchResult from "../components/SearchResult";
import CharityDetail from "../components/CharityDetail";
import OGNLaunchStory from "../components/NewsStories";
import GivingEfficiency from "../components/NewsStories/GivingEfficiency";
import OGNRatings from "../components/NewsStories/OGNRatings";
import CharityBondResults from "../components/CharityBondResults";
import DataV2 from "../components/NewsStories/DataV2";
import OGNPortfolioNews from "../components/NewsStories/OGNPortfolioNews";
import OGNSmartSearchNews from "../components/NewsStories/OGNSmartSearchNews";
import StarRating from "../components/NewsStories/StarRating";

class MainRouter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavbarMain />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/news-and-features" component={NewsFeatures} />
          <Route path="/support-us" component={SupportUs} />
          <Route path="/what-we-do" component={WhatWeDo} />
          <Route path="/search-result" component={SearchResult} />
          <Route path="/charity-detail/:charpassno" component={CharityDetail} />
          <Route path="/ogn-launch" component={OGNLaunchStory} />
          <Route path="/giving-efficiency" component={GivingEfficiency} />
          <Route path="/ogn-ratings-overview" component={OGNRatings} />
          <Route path="/ognportfolioresults" component={CharityBondResults} />
          <Route path="/ognportfolionews" component={OGNPortfolioNews} />
          <Route path="/ognsmartsearchnews" component={OGNSmartSearchNews} />
          <Route path="/datav2" component={DataV2} />
          <Route path="/smartsearch" component={SmartSearch} />
          <Route path="/starrating" component={StarRating} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default MainRouter;
