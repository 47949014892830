import React, { Fragment } from "react";

// import style sheet
import "./newsandfeatures.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/news-and-features.jpg";
import MiniLogo from "../../assets/images/logo-mini.jpg";
import Ratings from "../../assets/images/RATINGS.jpg";
import OGNIcon from "../../assets/images/opengivinglogo.jpg";
import Accounting from "../../assets/images/accounting.jpg";
import Covid from "../../assets/images/covid.jpg";
import LondonMarathon from "../../assets/images/londonmarathon.jpg";
import GivingEfficiency from "../../assets/images/givingefficiency.jpg";
import DataVer2 from "../../assets/images/datav2front.jpg";
import OGNPImage from "../../assets/images/ognportfoliofrontpage.jpg";
import SmartSearchNews from "../../assets/images/smartsearchnews.jpg";
import StarRatingIcon from "../../assets/images/StarRating.jpg";
import TomMooreCharity from "../../assets/images/TomMooreCharityInvestigate.jpg";

// import components
import LatestNews from "../LatestNews";
import Footer from "../Footer";

// import content of the news stories
//import { data } from "./newsstories";

//const NewsFeatures = () => {
class NewsFeatures extends React.Component {

    render() {

    
  return (
    <Fragment>
      <Container>
        <div className="news-and-features-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news"/>
        </div>
      </Container>
      <Container>
      <div className="latest-news-wrapper">
        <div className="latest-news-content">
          <h2>News & Features</h2>
          <div className="news-articles">
            <div className="article">
            <img src={TomMooreCharity} className="img-fluid" alt="article-img" />
              <a href="https://www.theguardian.com/society/2022/jun/30/family-of-captain-sir-tom-moore-face-formal-inquiry-into-charity-setup" target="_blank">
              <h5>Captain Sir Tom Moore foundation to face formal inquiry | June 2022</h5></a>
              <p>
              Charity Commission to investigate concerns family personally profited from charity set up in veteran’s name
              </p>
            </div>
            <div className="article">
            <img src={StarRatingIcon} className="img-fluid" alt="article-img" />
              <a href="/starrating">
              <h5>Star Ratings Introduced | June 2022</h5></a>
              <p>
              Star Ratings introduced and search accuracy boosted
              </p>
            </div>
            <div className="article">
            <img src={SmartSearchNews} className="img-fluid" alt="article-img" />
              <a href="/ognsmartsearchnews">
              <h5>Smart Search Launched | Sep 2021</h5></a>
              <p>
              OGN merges the ease of the simple search and the power of the advanced search 
              </p>
            </div>
            <div className="article">
              <img src={OGNPImage} className="img-fluid" alt="article-img" />
              <a href="/ognportfolionews">
              <h5>OGN Portfolio Launched | June 2021</h5></a>
              <p>
              Want to spread your donations wisely? Welcome to the next generation of philanthropy
              </p>
            </div>
            <div className="article">
              <img src={DataVer2} className="img-fluid" alt="article-img" />
              <a href="/datav2" target="_blank">
              <h5>OGN Data Just Got Serious | June 2021</h5></a>
              <p>
              Better data. Better decisions. More impact 
              </p>
            </div>
            <div className="article">
              <img src={Ratings} className="img-fluid" alt="article-img" />
              <a href="/ogn-ratings-overview">
                <h5>OGN Ratings Launched | Mar 2021</h5></a>
              <p>
                We rate charities based on reliability and performance. Here's how we did it.
              </p>
            </div>
            <div className="article">
              <img src={GivingEfficiency} className="img-fluid" alt="article-img" />
              <a href="/giving-efficiency">
              <h5>Giving Efficiency Explained | Mar 2021</h5></a>
              <p>
              We drill down into the pros and cons of giving efficiency
              </p>
            </div>
            <div className="article">
              <img src={Accounting} className="img-fluid" alt="article-img" />
              <a href="https://www.thinknpc.org/resource-hub/keeping-account-a-guide-to-charity-financial-analysis/" target="_blank">
                <h5>Keeping Account: Charity Financial Analysis | Feb 2021</h5></a>
              <p>
              NPC examine how we should be looking at the data when looking where to invest
              </p>
            </div>
            <div className="article">
              <img src={Covid} className="img-fluid" alt="article-img" />
              <a href="https://www.thinknpc.org/resource-hub/coronavirus-guide/" target="_blank">
              <h5>Philanthropy and Covid | Feb 2021</h5></a>
              <p>
              Philanthropists encouraged to re-engage with the charity sector
              </p>
            </div>
            <div className="article">
              <img src={LondonMarathon} className="img-fluid" alt="article-img" />
              <a href="https://www.thirdsector.co.uk/london-marathon-plans-100000-participant-hybrid-event-overhauls-controversial-bond-system/fundraising/article/1705150" target="_blank">
              <h5>London Marathon | Feb 2021</h5></a>
              <p>
              London Marathon organisers announce hybrid event for 100,000 participants
              </p>
            </div>
            <div className="article">
              <img src={OGNIcon} className="img-fluid" alt="article-img" />
              <a href="/ogn-launch">
                <h5>Open Giving Has Launched! | Feb 2021</h5></a>
              <p>
                Here's a little insight into the journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
      <Container>
          <hr className="hr-news"/>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      </Container>
      <Footer />
    </Fragment>
  );
};
}

export default NewsFeatures;
