import React, { Fragment } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

// import style sheet
import "./home.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import images
import Refresh from "../../assets/images/refresh.png";
import MiniLogo from "../../assets/images/logo-mini.jpg";
import PortfolioBanner from "../../assets/images/portfoliobanner.jpg";

// import components
import LatestNews from "../LatestNews";
import Footer from "../Footer";

class Home extends React.Component {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      regions: [],
      selectedRegion: "",
      causes: [],
      selectedCause: "",
      basicSearch: true,
      errorText: "",
      redirectOK: false
    };

    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleCauseChange = this.handleCauseChange.bind(this);
    this.checkBasicFields = this.checkBasicFields.bind(this);
  }

  componentDidMount() {

    //No need to fetch any data

  }

  handleRegionChange(event) {     
    this.setState({selectedRegion: event.value}); 
  }

  handleCauseChange(event) {    
    this.setState({selectedCause: event.value});  
  }
  
  checkBasicFields () {
    
    this.setState({redirectOK: true});

  }

  render() {

  var regionOptions = [];
    this.state.regions.forEach(function(element) {
      regionOptions.push({ label:element, value: element })
  });  

  //var searchDisabled = false;
  //if ((!(this.state.selectedRegion===""))&&(!(this.state.selectedCause===""))) {
  //  searchDisabled=false;
  //}

  var buttonEmbed;
    if (this.state.redirectOK === false) {
      buttonEmbed = 
      <button type="button" className="search-button" onClick={this.checkBasicFields}>Start My Search!</button>
                
    }
    else {
      buttonEmbed = <Redirect push to={{
                      pathname: "/smartsearch"
                      }}
                      >
                    </Redirect>
    }

  return (
    <Fragment>
      <Container>
        <div className="home-container">
          <Row>
            <Col md={5}>
              <div className="charity-search">
                <h1>Find your perfect charity</h1>
                <br></br><br></br>
                {buttonEmbed}
                <br></br><br></br><br></br><br></br>
              </div>
            </Col>
          </Row>
        </div>
      <hr className="hr-home"/>
      </Container>

      <Container>
      <div className="second-home-container"><br></br>
      <h4 style={{color: "#2f527a"}}>As we advance further towards a culture which values philanthrophy, many people are asking themselves how they can donate reliably to charitable causes that matter
              to them. Open Giving Now gives you the data you need to make the right decision for you, and for the world. We always put the donor before profit.</h4>
      </div>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <hr className="hr-home"/>
      </Container>

      <LatestNews />
      <Container>
        <div className="second-home-container">
          <hr className="hr-homebottom"/>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
}
}

export default Home;
