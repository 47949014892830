import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/datav2banner.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";


class DataV2 extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>OGN Data Just Got Serious</h1><br></br><br></br>
            <h5>Tuesday 22nd June 2021</h5><br></br><br></br>
            <p>Since the launch of<b> Open Giving Now</b>, we have been providing you with the very latest data as it is released from the UK 
              charities commission, along with the presentation of the KPIs that matter most to you. We are delighted to announce that 
              after a busy few months, we are now compliant with the very latest data feed, providing you with even better information 
              to make your donating decisions.
            <br></br><br></br>
            The question you're probably asking is "How is it better?"
              <br></br><br></br>              
              Firstly, the list of charities we show you are now even more stringently checked. For example, we now exclude any charities 
              that are officially listed as in administration, insolvent, or dissolved, and even make sure that all listed charities accept 
              public donations. Secondly, the quality of the data we present is better. Among other things, we now show if a charity is 
              registered for Gift Aid, their purpose (as written by them), their official financial reporting status , whether they use 
              professional fundraisers, even how much income they get from government grants or contracts!
              <br></br><br></br>
              As before, we don't just display all of the KPIs and contributing data under the details for each charity, so you can 
              corroborate your decisions. All of this extra information has been factored into the KPIs, including the performance and 
              reliability scores that we calculate, so you can donate more effectively than ever before.

            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default DataV2;
