import React, { Fragment } from "react";
import { SocialIcon } from 'react-social-icons';

// import bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import style sheet
import "./footer.scss";

class Footer extends React.Component {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      mailText: ""
    };

    this.handleMailText = this.handleMailText.bind(this);
  }

  handleMailText(event) {     
    this.setState({mailText: event.target.value}); 
  }

  sendMailButton() {
    //Send a mail here based on the address entered in the text field

  }

  render () {
    
  return (
    <Fragment>
      <div className="footer-container">
      <Container>
      <div className="footer-link">
          <Row>
            <Col md={3}>
          <h5>Useful Links</h5><br></br>
            <ul>
              <li className="footer-list">
                <a href="/what-we-do">What We Do</a>
              </li>
              <li className="footer-list">
                <a href="/support-us">Support Us</a>
              </li>
              <li className="footer-list">
                <a href="/about-us">About Us</a>
              </li>
              <li className="footer-list">
                <a href="/news-and-features">News/Features</a>
              </li>
              <li className="footer-list">
                <a href="/smartsearch">Find Your Charity</a>
              </li>
            </ul>
            <br></br>
          </Col>
          <Col md={3}>
          <h5>Resources</h5><br></br>
          <ul>
              <li className="footer-list">
                <a href="https://www.charitytoday.co.uk/" target="_blank">Charity Today</a>
              </li>
              <li className="footer-list">
                <a href="https://www.charitytimes.com/ct/index.php" target="_blank">Charity Times</a>
              </li>
              <li className="footer-list">
                <a href="https://www.thirdsector.co.uk/news" target="_blank">Third Sector</a>
              </li>
              <li className="footer-list">
                <a href="https://www.thinknpc.org/" target="_blank">New Philanthropy Capital</a>
              </li>
            </ul>
            <br></br>
          </Col>
          <Col md={3}>
          <h5>Follow Us</h5><br></br>
          <SocialIcon style={{ height: 40, width: 40, padding: 10 }} url="https://www.facebook.com/opengivingnow" />
          &nbsp;&nbsp;<SocialIcon style={{ height: 40, width: 40, padding: 10 }} url="https://www.linkedin.com/company/open-giving-now" />
          &nbsp;&nbsp;<SocialIcon style={{ height: 40, width: 40, padding: 10 }} url="http://www.instagram.com/opengivingnow" />
          <br></br><br></br>
          </Col>
          <Col md={3}>
          <h6>We would love to keep you posted, so why not sign up to our newsletter?</h6>
          <div className="mail-part">  
          <form action="https://opengivingnow.us7.list-manage.com/subscribe/post?u=5d6766e6b7c1c95b29688b76d&amp;id=0186955df3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <input 
                      type="text"  
                      className="mailtextentry" />
          <button type="submit" className="mail-button">Subscribe</button>
          </form>
          </div>
          <br></br>
          <a href="mailto: contactus@opengivingnow.com">Contact Us</a>
            <p className="mail-part">Copyright © OpenGivingNow 2021</p>
          </Col>
          </Row>
          </div>
      </Container>
        </div>
    </Fragment>
  );
};
}

export default Footer;
