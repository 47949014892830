import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/StarRatingBanner.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";


class StarRating extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>Star Ratings</h1><br></br><br></br>
            <h5>Monday 13th June 2022</h5><br></br><br></br>
            <p>At<b> Open Giving Now</b> we get a buzz every time we match a donor to a charity, and like our donors and featured charities, 
              we ask ourselves the same question every day: “How can we be better?”
            <br></br><br></br>
               We’ve taken feedback from donors, and simplified things in two ways.
            <br></br><br></br>
              Firstly, we’ve introduced the star rating, rolling up the performance and reliability ratings to reflect the fact that 
              most donors use both to justify a decision. This allows you to see which charities are doing well on both metrics, at a 
              quick glance.
              <br></br><br></br>              
              Secondly, we’ve improved the sorting to focus on the causes that matter to you. If you’re after a charity which focusses 
              on the elderly, your selection will now propel all those charities that focus on the elderly up through the search 
              rankings, making it even easier to find the charities that matter to you.
              <br></br><br></br>
              Don’t take our word for it, go take a look and see what you think!

            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default StarRating;
