import React, { Fragment } from "react";
import Select from "react-select";
import { Redirect } from "react-router";
import { BsInfoCircle } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ProgressBar from "./ProgressBar";

// import style sheet
import "./smartsearch.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import images
import MiniLogo from "../../assets/images/logo-mini.jpg";
import Footer from "../Footer";

import {targetHost} from "../../utils/constants";
import {targetProtocol} from "../../utils/constants";
import {apiUsername} from "../../utils/constants";
import {apiPassword} from "../../utils/constants";

//const AdvanceSearch = () => {
  class SmartSearch extends React.Component {

    constructor(props) {
      super(props);
      // Don't call this.setState() here!
      this.state = {
        searchText: "",
        UKRegions: [],
        londonBoroughs: [],
        localAreas: [],
        regions: [],
        locations: [],
        selectedUKRegion: "",
        selectedLondonBorough: "",
        selectedLocalArea: "",
        selectedRegion: "",
        selectedLocation: "",
        causes: [],
        selectedCause: "",
        selectedSecondaryCause: "",
        selectedVolMin: "",
        selectedVolMax: "",
        selectedEmpMin: "",
        selectedEmpMax: "",
        selectedBoutique: "No",
        selectedGiftAid: "No",
        errorText: "",
        basicSearch: false,
        redirectOK: false,
        selectedLocationGranularity: "NS",
        pageNumber: 1,
        buttonText: "Next"
      };
  
      this.handleSearchText = this.handleSearchText.bind(this);
      this.handleUKRegionChange = this.handleUKRegionChange.bind(this);
      this.handleLondonBoroughChange = this.handleLondonBoroughChange.bind(this);
      this.handleLocalAreaChange = this.handleLocalAreaChange.bind(this);
      this.handleRegionChange = this.handleRegionChange.bind(this);
      this.handleLocationChange = this.handleLocationChange.bind(this);
      this.handleVolMinChange = this.handleVolMinChange.bind(this);
      this.handleVolMaxChange = this.handleVolMaxChange.bind(this);
      this.handleEmpMinChange = this.handleEmpMinChange.bind(this);
      this.handleEmpMaxChange = this.handleEmpMaxChange.bind(this);
      this.checkFields = this.checkFields.bind(this);
      this.nextPage = this.nextPage.bind(this);
      this.onChangeAOO = this.onChangeAOO.bind(this);
      this.onChangeGiftAid = this.onChangeGiftAid.bind(this);
      this.onChangeBoutique = this.onChangeBoutique.bind(this);
      this.onChangeCause = this.onChangeCause.bind(this);
      this.onChangeSecondaryCause = this.onChangeSecondaryCause.bind(this);
      this.handleKeypress = this.handleKeypress.bind(this);
    }

    componentDidMount() {

      fetch(
        `${targetProtocol}://${targetHost}/locations?username=${apiUsername}&password=${apiPassword}&geoArea=COUNTRY`
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          let locationsFromApi = data.map((retrievedLocation) => {
            return { value: retrievedLocation.location, label: retrievedLocation.location
            };  //Sets up locationsFromApi as an object with value and display attributes
          }); 
          this.setState({ //Add one at the top which invites you to select a location
            locations: locationsFromApi
          });
        })
        .catch(error => {
        });

        fetch(
          `${targetProtocol}://${targetHost}/locations?username=${apiUsername}&password=${apiPassword}&geoArea=MULTIAREA`
        )
          .then(res => {
            return res.json();
          })
          .then(data => {
            let UKRegionsFromApi = data.map((retrievedUKRegion) => {
              return { value: retrievedUKRegion.location, label: retrievedUKRegion.location
              };  //Sets up locationsFromApi as an object with value and display attributes
            }); 
            this.setState({ //Add one at the top which invites you to select a location
              UKRegions: UKRegionsFromApi
            });
          })
          .catch(error => {
          });

          fetch(
            `${targetProtocol}://${targetHost}/locations?username=${apiUsername}&password=${apiPassword}&geoArea=LDNBOR`
          )
            .then(res => {
              return res.json();
            })
            .then(data => {
              let londonBoroughsFromApi = data.map((retrievedlondonBorough) => {
                return { value: retrievedlondonBorough.location, label: retrievedlondonBorough.location
                };  //Sets up locationsFromApi as an object with value and display attributes
              }); 
              this.setState({ //Add one at the top which invites you to select a location
                londonBoroughs: londonBoroughsFromApi
              });
            })
            .catch(error => {
            });

            fetch(
              `${targetProtocol}://${targetHost}/locations?username=${apiUsername}&password=${apiPassword}&geoArea=AREA`
            )
              .then(res => {
                return res.json();
              })
              .then(data => {
                let localAreasFromApi = data.map((retrievedLocalArea) => {
                  return { value: retrievedLocalArea.location, label: retrievedLocalArea.location
                  };  //Sets up locationsFromApi as an object with value and display attributes
                }); 
                this.setState({ //Add one at the top which invites you to select a location
                  localAreas: localAreasFromApi
                });
              })
              .catch(error => {
              });

        fetch(
          `${targetProtocol}://${targetHost}/regions?username=${apiUsername}&password=${apiPassword}&includeuk=false`
        )
          .then(res => {
            return res.json();
          })
          .then(data => {
            let regionsFromApi = data.map((retrievedRegion) => {
              return { value: retrievedRegion.region, label: retrievedRegion.region
              };  //Sets up locationsFromApi as an object with value and display attributes
            }); 
            this.setState({ //Add one at the top which invites you to select a location
              regions: regionsFromApi
            });
          })
          .catch(error => {
            console.log(error);
          });
  
        fetch(
          `${targetProtocol}://${targetHost}/causes?username=${apiUsername}&password=${apiPassword}`
        )
          .then(res => {
            return res.json();
          })
          //.then(res => console.log(res))   //This logs out the output of the res return but will spoil the next then clause
          .then(data => {
            let causesFromApi = data.map((retrievedCause) => {
              return { value: retrievedCause.cause, label: retrievedCause.cause
              };  //Sets up locationsFromApi as an object with value and display attributes
            }); 
            this.setState({ //Add one at the top which invites you to select a location
              causes: causesFromApi
            });
          })
          .catch(error => {
            console.log(error);
          });
  
    }

    handleVolMinChange(event) {     
      console.log("Min Volunteers Selected");
      this.setState({selectedVolMin: event.value}); 
    }
  
    handleVolMaxChange(event) {    
      console.log("Max Volunteers Selected");
      this.setState({selectedVolMax: event.value});  
    }

    handleEmpMinChange(event) {     
      console.log("Min Employees Selected: "+event.value);
      this.setState({selectedEmpMin: event.value}); 
    }
  
    handleEmpMaxChange(event) {    
      console.log("Max Employees Selected");
      this.setState({selectedEmpMax: event.value});  
    }

    handleUKRegionChange(event) {     
      console.log("UK Region Selected");
      this.setState({selectedUKRegion: event.value});  
      this.setState({buttonText: "Search"});
    }

    handleLondonBoroughChange(event) {     
      console.log("London Borough Selected");
      this.setState({selectedLondonBorough: event.value});  
      this.setState({buttonText: "Search"});
    }

    handleLocalAreaChange(event) {     
      console.log("Local Area Selected");
      this.setState({selectedLocalArea: event.value});  
      this.setState({buttonText: "Search"});
    }

    handleLocationChange(event) {     
      console.log("Location Selected");
      this.setState({selectedLocation: event.value});  
      this.setState({buttonText: "Search"});
    }

    handleRegionChange(event) {     
      console.log("Region Selected");
      this.setState({selectedRegion: event.value});  
      this.setState({buttonText: "Search"});

      fetch(
        `${targetProtocol}://${targetHost}/locationsbyregion?username=${apiUsername}&password=${apiPassword}&region=${event.value}`
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          let locationsFromApi = data.map((retrievedLocation) => {
            return { value: retrievedLocation.location, label: retrievedLocation.location
            };  //Sets up locationsFromApi as an object with value and display attributes
          }); 
          this.setState({ //Add one at the top which invites you to select a location
            locations: locationsFromApi
          });
        })
        .catch(error => {
          console.log(error);
        });
    }

    handleSearchText(event) {     
      this.setState({searchText: event.target.value});  
      this.setState({buttonText: "Next"});
    }

    onChangeAOO(event) {     
      this.setState({selectedLocationGranularity: event.target.value});  
      this.setState({buttonText: "Next"});
    }

    onChangeGiftAid(event) {     
      this.setState({selectedGiftAid: event.target.value});  
      this.setState({buttonText: "Next"});
    }

    onChangeBoutique(event) {     
      this.setState({selectedBoutique: event.target.value});  
      this.setState({buttonText: "Next"});
    }

    onChangeCause(event) {     
      this.setState({selectedCause: event.target.value}); 
      this.setState({buttonText: "Next"});
    }

    onChangeSecondaryCause(event) {     
      this.setState({selectedSecondaryCause: event.target.value}); 
    }

    handleKeypress(event) {     
      if (event.keyCode === 13) {
        this.setState({redirectOK: true});
      } 
    }

    checkFields () {

      var errorMessage = "";

      if (errorMessage === "") {
        this.setState({redirectOK: true});
      }
      else {
        this.setState({errorText: errorMessage})
      }
    }
  
    nextPage() {  
      
      if ((this.state.pageNumber === 2)) {
        this.setState({buttonText: "Skip to Results"});
      }
      else if ((this.state.pageNumber === 1)) {
        this.setState({buttonText: "Skip"});
        window.scrollTo(0, 0);
      }
      else if (this.state.pageNumber === 3) {
        this.setState({buttonText: "Skip to Results"});
      }

      if ((this.state.buttonText === "Skip to Results")||(this.state.buttonText === "Search")) {
        if (this.state.selectedLocationGranularity === "NS") {
          this.setState({selectedLocationGranularity: "All"});
        }
        this.setState({redirectOK: true});
      }
      else {
        this.setState({pageNumber: (this.state.pageNumber+1)});
      }
      
    }
  
  render() {

    var buttonEmbed;
    if (this.state.redirectOK === false) {
      buttonEmbed = 
      <button onClick={this.nextPage}>
        {this.state.buttonText}
      </button>
    }
    else {
      buttonEmbed = <Redirect push to={{
                      pathname: "/search-result",
                      state: {
                        inputSearchText: this.state.searchText,
                        inputCause: this.state.selectedCause,
                        inputSecondaryCause: this.state.selectedSecondaryCause,
                        inputUKRegion: this.state.selectedUKRegion,
                        inputLondonBorough: this.state.selectedLondonBorough,
                        inputLocalArea: this.state.selectedLocalArea,
                        inputRegion: this.state.selectedRegion,
                        inputLocation: this.state.selectedLocation,
                        inputVolMin: this.state.selectedVolMin,
                        inputVolMax: this.state.selectedVolMax,
                        inputEmpMin: this.state.selectedEmpMin,
                        inputEmpMax: this.state.selectedEmpMax,
                        inputBasicSearch: this.state.basicSearch,
                        inputBoutique: this.state.selectedBoutique,
                        inputGiftAid: this.state.selectedGiftAid,
                        inputLocationGranularity: this.state.selectedLocationGranularity
                      } 
                      }}
                      >
                    </Redirect>
    }

    const multiTooltip = props => (
      <Tooltip {...props}>A charity will list as Multi Area if their area of operation is in greater than 10 Local Areas or London Boroughs</Tooltip>
    );
    const LATooltip = props => (
      <Tooltip {...props}>A local charity may not list work performed in a Local Area, instead choosing to list their area of operation as a Multi-Area</Tooltip>
    );

  return (
    <Fragment>
      <Container>
        <div className="advance-search-wrapper">
          <hr className="hr-adv"/>
          <div className="green-bg">
            <div className="advance-search-content">
                {this.state.pageNumber === 2 &&
                  <div>
                  <Row>
                    <Col md={12}>
                      <ProgressBar bgcolor="#2f527a" completed="25" />
                      <br></br><br></br>
                    </Col>
                  </Row>
                    <Row>
                      <Col md={12}>
                          <br></br>
                          <div className="title">
                            <h2>Keywords</h2>
                          </div>
                          <br></br><br></br>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                      <div className="select-textentry">
                        <input 
                          type="text" 
                          value={this.state.value} 
                          placeholder="e.g. dementia, mental health" 
                          className="textentry"
                          onKeyPress={this.handleKeypress}
                          onChange={this.handleSearchText.bind(this)} />
                      </div>
                      </Col>
                    </Row>
                  </div>
                }
                {this.state.pageNumber === 3 &&
                  <div>
                    <Row>
                      <Col md={12}>
                        <ProgressBar bgcolor="#2f527a" completed="50" />
                        <br></br><br></br>
                      </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                        <br></br>
                          <div className="title">
                            <h2>Area of Impact</h2>
                          </div>
                          <br></br><br></br>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <form className="form-choice">
                          <label className="label-choice">
                            <input type="radio" value="All" name="globalchoice" onChange={this.onChangeAOO} checked={this.state.selectedLocationGranularity === 'All'}/>
                            <span>Global</span>
                          </label>
                        </form>
                      </Col>
                      <Col md={6}>
                        <form className="form-choice">
                          <label className="label-choice">
                            <input type="radio" value="UK" name="globalchoice" onChange={this.onChangeAOO} checked={this.state.selectedLocationGranularity === 'UK'}/>
                            <span>UK</span>
                          </label>
                        </form>
                        </Col>
                    </Row>
                  </div>
                }
                {this.state.pageNumber === 4 &&
                  <div>
                  <Row>
                    <Col md={12}>
                      <ProgressBar bgcolor="#2f527a" completed="75" />
                      <br></br><br></br>
                    </Col>
                  </Row>
                    <Row>
                      <Col md={12}>
                      <br></br>
                          <div className="title">
                            <h2>Specific Area<br></br>(Optional)</h2>
                          </div>
                          <br></br><br></br>
                      </Col>
                    </Row>
                    <Row>
                    {this.state.selectedLocationGranularity === "UK" &&
                  <Col md={6}>
                    <fieldset className="fieldset-adv">
                    <p className="paragraphspacer">UK Area</p>
                    <div className="select-info-dropdown">
                      <Select
                      id="dropdown"
                        onChange={this.handleUKRegionChange.bind(this)}
                        options={this.state.UKRegions}
                        classNamePrefix="charity"
                        placeholder="Select Multi Area"
                      />
                      <OverlayTrigger placement="top" overlay={multiTooltip}><BsInfoCircle/></OverlayTrigger>
                    </div>
                    <div className="select-info-dropdown">
                      <Select
                        onChange={this.handleLocalAreaChange.bind(this)}
                        options={this.state.localAreas}
                        classNamePrefix="charity"
                        placeholder="Select Local Area"
                      />
                      <OverlayTrigger placement="top" overlay={LATooltip}><BsInfoCircle/></OverlayTrigger>
                    </div>
                    <div className="select-dropdown">
                      <Select
                        onChange={this.handleLondonBoroughChange.bind(this)}
                        options={this.state.londonBoroughs}
                        classNamePrefix="charity"
                        placeholder="Select LDN Borough"
                      />
                    </div>
                    </fieldset>
                    <br></br>
                  </Col>
                  }
                  {this.state.selectedLocationGranularity === "All" &&
                  <Col md={6}>
                    <fieldset className="fieldset-adv">
                    <p className="paragraphspacer">Global Area</p>
                    <div className="select-dropdown">
                      <Select
                        onChange={this.handleRegionChange.bind(this)}
                        options={this.state.regions}
                        classNamePrefix="charity"
                        placeholder="Select Continent"
                      />
                    </div>
                    <div className="select-dropdown">
                      <Select
                        onChange={this.handleLocationChange.bind(this)}
                        options={this.state.locations}
                        classNamePrefix="charity"
                        placeholder="Select Country/City"
                      />
                    </div>
                    </fieldset>
                  </Col>
                  }
                    </Row>
                  </div>
                }
                {this.state.pageNumber === 1 &&
                    <div>
                    <Row>
                      <Col md={12}>
                        <ProgressBar bgcolor="#2f527a" completed="0" />
                        <br></br><br></br>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                      <br></br>
                              <div className="title">
                                <h2>Charitable Cause</h2>
                              </div>
                              <br></br><br></br>
                      </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                      {this.state.causes.slice(0,10).map(item => (
                              <React.Fragment>
                                <form className="form-choice">
                                  <label className="label-choice" htmlFor={item.value}>
                                    <input name="causes" key={item.value} id={item.value} value={item.value} type="radio" className="cause1" onChange={this.onChangeCause} checked={this.state.selectedCause === item.value}/>
                                    <span>{item.value}</span>
                                  </label></form>
                              </React.Fragment>
                          ))}
                          </Col>
                          <Col md={6}>
                          {this.state.causes.slice(10).map(item => (
                              <React.Fragment>
                                <form className="form-choice">
                                  <label className="label-choice" htmlFor={item.value}>
                                    <input name="causes" key={item.value} id={item.value} value={item.value} type="radio" className="cause1" onChange={this.onChangeCause} checked={this.state.selectedCause === item.value}/>
                                    <span>{item.value}</span>
                                  </label></form>
                              </React.Fragment>
                          ))}
                        </Col>
                      </Row>
                    </div>
                    }
                <Row>
                <Col md={12}>
                  <br></br><br></br>
                  <div className="search-btn">
                    {buttonEmbed}
                  </div>
                </Col>
                </Row>
            </div>
          </div>
        </div>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <hr className="hr-adv"/>
      </Container>
      <Footer />
    </Fragment>
  );
  };
};

export default SmartSearch;
