import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Import Cause Icons
import AnimalsIcon from "../../assets/images/causeicons/animals.png";
import ArtsIcon from "../../assets/images//causeicons/arts.png";
import ChildrensIcon from "../../assets/images/causeicons/childrens.png";
import CommunityIcon from "../../assets/images/causeicons/community.png";
import DisabilitiesIcon from "../../assets/images/causeicons/disabilities.png";
import EducationIcon from "../../assets/images/causeicons/education.png";
import ElderlyIcon from "../../assets/images/causeicons/elderly.png";
import EnvironmentIcon from "../../assets/images/causeicons/environment.png";
import EthnicIcon from "../../assets/images/causeicons/ethnic.png";
import GeneralIcon from "../../assets/images/causeicons/general.png";
import HomelessIcon from "../../assets/images/causeicons/homeless.png";
import HumanIcon from "../../assets/images/causeicons/human.png";
import MilitaryIcon from "../../assets/images/causeicons/military.png";
import OverseasIcon from "../../assets/images/causeicons/overseas.png";
import PhysicalIcon from "../../assets/images/causeicons/physical.png";
import PovertyIcon from "../../assets/images/causeicons/poverty.png";
import RecreationIcon from "../../assets/images/causeicons/recreation.png";
import ReligionIcon from "../../assets/images/causeicons/religion.png";
import SportsIcon from "../../assets/images/causeicons/sports.png";

class DisplayCauseIcons extends Component {

  constructor(props) {
    super(props);
    const { causes_mnemonic = null } = props;

    this.causes_mnemonic = typeof causes_mnemonic === 'string' ? causes_mnemonic : 'j';
  }

  render() {

    var displayAnimal = false;
    var displayArts = false;
    var displayChildrens = false;
    var displayCommunity = false;
    var displayDisabilities = false;
    var displayEducation = false;
    var displayElderly = false;
    var displayEnvironment = false;
    var displayEthnic = false;
    var displayGeneral = false;
    var displayHomeless = false;
    var displayHuman = false;
    var displayMilitary = false;
    var displayOverseas = false;
    var displayPhysical = false;
    var displayPoverty = false;
    var displayRecreation = false;
    var displayReligion = false;
    var displaySports = false;

    if (this.causes_mnemonic.includes('a')) {
        displayAnimal = true;
    }
    if (this.causes_mnemonic.includes('b')) {
        displayArts = true;
    }
    if (this.causes_mnemonic.includes('c')) {
        displayChildrens = true;
    }
    if (this.causes_mnemonic.includes('d')) {
        displayCommunity = true;
    }
    if (this.causes_mnemonic.includes('e')) {
        displayDisabilities = true;
    }
    if (this.causes_mnemonic.includes('f')) {
        displayEducation = true;
    }
    if (this.causes_mnemonic.includes('g')) {
        displayElderly = true;
    }
    if (this.causes_mnemonic.includes('h')) {
        displayEnvironment = true;
    }
    if (this.causes_mnemonic.includes('i')) {
        displayEthnic = true;
    }
    if (this.causes_mnemonic.includes('j')) {
        displayGeneral = true;
    }
    if (this.causes_mnemonic.includes('k')) {
        displayHomeless = true;
    }
    if (this.causes_mnemonic.includes('l')) {
        displayHuman = true;
    }
    if (this.causes_mnemonic.includes('m')) {
        displayMilitary = true;
    }
    if (this.causes_mnemonic.includes('n')) {
        displayOverseas = true;
    }
    if (this.causes_mnemonic.includes('o')) {
        displayPhysical = true;
    }
    if (this.causes_mnemonic.includes('p')) {
        displayPoverty = true;
    }
    if (this.causes_mnemonic.includes('q')) {
        displayRecreation = true;
    }
    if (this.causes_mnemonic.includes('r')) {
        displayReligion = true;
    }
    if (this.causes_mnemonic.includes('s')) {
        displaySports = true;
    }

    const animalsTooltip = props => (<Tooltip {...props}>Animals and Welfare</Tooltip>);
    const artsTooltip = props => (<Tooltip {...props}>Arts and Science</Tooltip>);
    const childrensTooltip = props => (<Tooltip {...props}>Childrens Welfare</Tooltip>);
    const communityTooltip = props => (<Tooltip {...props}>Community Development</Tooltip>);
    const disabilitiesTooltip = props => (<Tooltip {...props}>Disabilities</Tooltip>);
    const educationTooltip = props => (<Tooltip {...props}>Education</Tooltip>);
    const elderlyTooltip = props => (<Tooltip {...props}>Elderly</Tooltip>);
    const environmentTooltip = props => (<Tooltip {...props}>Environment and Conservation</Tooltip>);
    const ethnicTooltip = props => (<Tooltip {...props}>Ethnic</Tooltip>);
    const generalTooltip = props => (<Tooltip {...props}>General</Tooltip>);
    const homelessTooltip = props => (<Tooltip {...props}>Homelessness</Tooltip>);
    const humanTooltip = props => (<Tooltip {...props}>Human Rights and Diversity</Tooltip>);
    const militaryTooltip = props => (<Tooltip {...props}>Military/Emergency Services</Tooltip>);
    const overseasTooltip = props => (<Tooltip {...props}>Overseas Aid/Famine Relief</Tooltip>);
    const physicalTooltip = props => (<Tooltip {...props}>Physical and Mental Health</Tooltip>);
    const povertyTooltip = props => (<Tooltip {...props}>Poverty</Tooltip>);
    const recreationTooltip = props => (<Tooltip {...props}>Recreation and Wellness</Tooltip>);
    const religionTooltip = props => (<Tooltip {...props}>Religion</Tooltip>);
    const sportsTooltip = props => (<Tooltip {...props}>Sports</Tooltip>);

    return (
      <Fragment>
        { displayAnimal === true ? <OverlayTrigger placement="top" overlay={animalsTooltip}><img src={AnimalsIcon} alt="logo" className="img-fluid" width="50" /></OverlayTrigger> : "" }
        { displayArts === true ? <OverlayTrigger placement="top" overlay={artsTooltip}><img src={ArtsIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayChildrens === true ? <OverlayTrigger placement="top" overlay={childrensTooltip}><img src={ChildrensIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayCommunity === true ? <OverlayTrigger placement="top" overlay={communityTooltip}><img src={CommunityIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayDisabilities === true ? <OverlayTrigger placement="top" overlay={disabilitiesTooltip}><img src={DisabilitiesIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayEducation === true ? <OverlayTrigger placement="top" overlay={educationTooltip}><img src={EducationIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayElderly === true ? <OverlayTrigger placement="top" overlay={elderlyTooltip}><img src={ElderlyIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayEnvironment === true ? <OverlayTrigger placement="top" overlay={environmentTooltip}><img src={EnvironmentIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayEthnic === true ? <OverlayTrigger placement="top" overlay={ethnicTooltip}><img src={EthnicIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayGeneral === true ? <OverlayTrigger placement="top" overlay={generalTooltip}><img src={GeneralIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayHomeless === true ? <OverlayTrigger placement="top" overlay={homelessTooltip}><img src={HomelessIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayHuman === true ? <OverlayTrigger placement="top" overlay={humanTooltip}><img src={HumanIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayMilitary === true ? <OverlayTrigger placement="top" overlay={militaryTooltip}><img src={MilitaryIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayOverseas === true ? <OverlayTrigger placement="top" overlay={overseasTooltip}><img src={OverseasIcon} alt="logo" className="img-fluid" width="50" /></OverlayTrigger> : "" }
        { displayPhysical === true ? <OverlayTrigger placement="top" overlay={physicalTooltip}><img src={PhysicalIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayPoverty === true ? <OverlayTrigger placement="top" overlay={povertyTooltip}><img src={PovertyIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayRecreation === true ? <OverlayTrigger placement="top" overlay={recreationTooltip}><img src={RecreationIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displayReligion === true ? <OverlayTrigger placement="top" overlay={religionTooltip}><img src={ReligionIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { displaySports === true ? <OverlayTrigger placement="top" overlay={sportsTooltip}><img src={SportsIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }

      </Fragment>
    );

  }
}

DisplayCauseIcons.propTypes = {
  causes_mnemonic: PropTypes.string.isRequired
};

export default DisplayCauseIcons;