import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/givingeffbanner.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";


class GivingEfficiency extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>Giving Efficiency Explained</h1><br></br><br></br>
            <h5>Monday 22 February 2021</h5><br></br><br></br>
            <p>When looking into giving to charity, the most common question that donors ask is: "What do they do with the money?"
            <br></br><br></br>
              For the most part it's addressed by a simple metric. For any given year, how much money does the charity DIRECTLY 
              spend on charitable activities, as a percentage of its total income (spending on charitable activities/total income x 100)
              <br></br><br></br>              
              In more relatable terms, if you donate £10 to a charity, how much of that will go to the causes you care about?
              <br></br><br></br>
              The good news is that charities which earn over £25k are obliged to submit detailed accounts, including the total 
              income and the total amount spent on charitable activities. These details are a minimum entry requirement for 
              <b> Open Giving Now</b>, and these figures are displayed for every listed charity. The caveat is that whilst giving 
              efficiency provides a lot of colour, it does need context.
              <br></br><br></br>              
              For example, a charity might have a high giving efficiency in a particular year by virtue of losing a lot of its 
              income stream, but still having obligations to service. These might take the obvious form of ongoing support and 
              governance costs, but could also be spending obligations made as part of legacies or endowments gifted previously. We 
              might then look at the reasons for the low income, the volatility of the income sources, along with the amount of 
              money they have in reserve to gain a fuller picture of whether this spending is sustainable. 
              <br></br><br></br>
              In contrast, a charity may have a low giving efficiency, but this may not be a clear indication that it is being 
              run poorly. Further inspection of its model might show that it is because it is gaining most of its money 
              through more reliable lower margin approaches, such as the running of charity shops.
              <br></br><br></br>
              Here at <b> Open Giving Now</b>, we have shown the giving efficiency for all charities, as it remains a key performance 
              indicator, and you can also quickly drill down into all of the underlying finances before making a decision. We 
              are constantly striving to join the dots in the data, with an ambition to bring you a rating for each charity which 
              will indicate both whether their financial management is built to withstand a financial hit, and also to show how 
              much of your money is being spent where it should be.

            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default GivingEfficiency;
