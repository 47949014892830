import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Redirect } from "react-router";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { HashLink } from 'react-router-hash-link';
import {isMobile} from 'react-device-detect';

// import style sheet
import "./searchresult.scss";

import AnimalsIcon from "../../assets/images/causeicons/animals.png";
import ArtsIcon from "../../assets/images//causeicons/arts.png";
import ChildrensIcon from "../../assets/images/causeicons/childrens.png";
import CommunityIcon from "../../assets/images/causeicons/community.png";
import DisabilitiesIcon from "../../assets/images/causeicons/disabilities.png";
import EducationIcon from "../../assets/images/causeicons/education.png";
import ElderlyIcon from "../../assets/images/causeicons/elderly.png";
import EnvironmentIcon from "../../assets/images/causeicons/environment.png";
import EthnicIcon from "../../assets/images/causeicons/ethnic.png";
import GeneralIcon from "../../assets/images/causeicons/general.png";
import HomelessIcon from "../../assets/images/causeicons/homeless.png";
import HumanIcon from "../../assets/images/causeicons/human.png";
import MilitaryIcon from "../../assets/images/causeicons/military.png";
import OverseasIcon from "../../assets/images/causeicons/overseas.png";
import PhysicalIcon from "../../assets/images/causeicons/physical.png";
import PovertyIcon from "../../assets/images/causeicons/poverty.png";
import RecreationIcon from "../../assets/images/causeicons/recreation.png";
import ReligionIcon from "../../assets/images/causeicons/religion.png";
import SportsIcon from "../../assets/images/causeicons/sports.png";
import StarRating from "../../assets/images/causeicons/Gold Star.png";

// import bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import images
import Banner from "../../assets/images/search-result.jpg";
import RetrievingResults from "../../assets/images/retrievingresults.png";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";

import { sortOptions } from "../../utils/constants";
import {targetHost} from "../../utils/constants";
import {targetProtocol} from "../../utils/constants";
import {apiUsername} from "../../utils/constants";
import {apiPassword} from "../../utils/constants";
import Pagination from './pagination';

//const {data} = this.props.location.data;
const pageLimitRows = 30;

class SearchResult extends React.Component {

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      searchRegion: "",
      searchLocation: "",
      searchUKRegion: "",
      searchLondonBorough: "",
      searchLocalArea: "",
      searchCause: "",
      searchSecondaryCause: "",
      searchVolMin: "",
      searchVolMax: "",
      searchEmpMin: "",
      searchEmpMax: "",
      searchInputText: "",
      searchBasic: true,
      searchBoutique: "",
      searchGiftAid: "",
      eligibleCharities: [],
      currentCharities: [],
      refinedSortOptions: [],
      currentPage: null,
      totalPages: null,
      organicEntry: true,
      selectedSortOrder: "Relevance",
      searchResultsPending: false,
      redirectOK: false,
      speed: 3,
      minPerformance: "60",
      minReliability: "60",
      searchLocationGranularity: "",
      selectedPortTopX: "5",
      selectedPortSortOrder: "relevance desc",
      firstTime: true
    };
  
    this.handleMinPerformanceSlider = this.handleMinPerformanceSlider.bind(this);
    this.handleMinReliabilitySlider = this.handleMinReliabilitySlider.bind(this);
    this.handlePortTopXChange = this.handlePortTopXChange.bind(this);
    this.handlePortSortOrderChange = this.handlePortSortOrderChange.bind(this);
    this.portButtonClicked = this.portButtonClicked.bind(this);
  }

  componentDidMount () {
    
    //Check if this request has come from a search page or if entered into URL
    if (this.props.location.state == null) {
      this.setState({organicEntry: false});
    }
    else {

      //Check if this request has come from the basic search or the advanced search
      const { inputBasicSearch } = this.props.location.state;
      this.setState({searchBasic: inputBasicSearch});

      //We want the rendering to wait for the results to come back
      this.setState({searchResultsPending: true});

      if (inputBasicSearch === true) {
        //Perform the search based only on the region and the cause sent in from the main page

        const { inputRegion } = this.props.location.state;
        this.setState({searchRegion: inputRegion});
        const { inputCause } = this.props.location.state;
        this.setState({searchCause: inputCause});

        //Add the necessary sort options
        const sortRegionObj = { value: "noregions asc, nolocations asc", label: "Region Focus" };
        const sortOptObjs = sortOptions.slice();
        if ((inputRegion !== null)&&(inputRegion !== '')) {
          sortOptObjs.push(sortRegionObj);
        }
            
        this.setState({ refinedSortOptions: sortOptObjs });

        //Fetch all the charities based on the region and cause
        fetch(
          `${targetProtocol}://${targetHost}/matchingcharitiesbasic?username=${apiUsername}&password=${apiPassword}&region=${inputRegion}&cause=${inputCause}&sortorder=${this.state.selectedPortSortOrder}`
        )
          .then(res => {
            return res.json();
          })
          .then(data => {
            let eligibleCharitiesFromApi = data.map((eligibleCharity) => {
              return { charityno: eligibleCharity.charno, charityweb: eligibleCharity.charweb, charityname: eligibleCharity.charname,
                      income: eligibleCharity.income, employees: eligibleCharity.employees, volunteers: eligibleCharity.volunteers,
                      nolocations: eligibleCharity.nolocations, nocauses: eligibleCharity.nocauses, noregions: eligibleCharity.noregions,
                      income_employees: eligibleCharity.income_employees, income_volunteers: eligibleCharity.income_volunteers,
                      num_yrs_finhist: eligibleCharity.num_yrs_finhist, causes_mnemonic : eligibleCharity.causes_mnemonic,
                      pc_restrict: eligibleCharity.pc_restrict, pc_giving : eligibleCharity.pc_giving, pc_income_growth: eligibleCharity.pc_income_growth,
                      pc_activities_growth: eligibleCharity.pc_activities_growth, list_regions: eligibleCharity.list_regions,
                      pc_giving_growth: eligibleCharity.pc_giving_growth, pc_safety: eligibleCharity.pc_safety, last_submitted: eligibleCharity.last_submitted,
                      reliability: eligibleCharity.reliability, performance: eligibleCharity.performance, pc_giving_3yr: eligibleCharity.pc_giving_3yr,
                      rating: eligibleCharity.rating, relevance: eligibleCharity.relevance,
              };  //Sets up locationsFromApi as an object with value and display attributes
            }); 
            this.setState({ 
              eligibleCharities: eligibleCharitiesFromApi
            });
            this.setState({searchResultsPending: false});
          })
          .catch(error => {
            console.log(error);
          });

      }
      else {
        //Perform the search based on all variables potentially passed in from the advanced search

        const { inputLocation } = this.props.location.state;
        this.setState({searchLocation: inputLocation});
        const { inputRegion } = this.props.location.state;
        this.setState({searchRegion: inputRegion});
        const { inputUKRegion } = this.props.location.state;
        this.setState({searchUKRegion: inputUKRegion});
        const { inputLocalArea } = this.props.location.state;
        this.setState({searchLocalArea: inputLocalArea});
        const { inputLondonBorough } = this.props.location.state;
        this.setState({searchLondonBorough: inputLondonBorough});
        const { inputSearchText } = this.props.location.state;
        this.setState({searchInputText: inputSearchText});
        const { inputCause } = this.props.location.state;
        this.setState({searchCause: inputCause});
        const { inputSecondaryCause } = this.props.location.state;
        this.setState({searchSecondaryCause: inputSecondaryCause});
        const { inputVolMin } = this.props.location.state;
        this.setState({searchVolMin: inputVolMin});
        const { inputVolMax } = this.props.location.state;
        this.setState({searchVolMax: inputVolMax});
        const { inputEmpMin } = this.props.location.state;
        this.setState({searchEmpMin: inputEmpMin});
        const { inputEmpMax } = this.props.location.state;
        this.setState({searchEmpMax: inputEmpMax});
        const { inputBoutique } = this.props.location.state;
        this.setState({searchBoutique: inputBoutique});
        const { inputGiftAid } = this.props.location.state;
        this.setState({searchGiftAid: inputGiftAid});
        const { inputLocationGranularity } = this.props.location.state;
        this.setState({searchLocationGranularity: inputLocationGranularity});

        //Add the necessary sort options
        const sortRegionObj = { value: "noregions asc, nolocations asc", label: "Region Focus" };
        const sortLocationObj = { value: "nolocations asc", label: "Area Focus" };
        const sortOptObjs = sortOptions.slice();
        if ((inputRegion !== null)&&(inputRegion !== '')) {
          sortOptObjs.push(sortRegionObj);
        }
        if (((inputLocation !== null)&&(inputLocation !== ""))||((inputUKRegion !== null)&&(inputUKRegion !== ""))
        ||((inputLondonBorough !== null)&&(inputLondonBorough !== ""))||((inputLocalArea !== null)&&(inputLocalArea !== ""))) {
          sortOptObjs.push(sortLocationObj);
        }

        var tempSortString = "";
        if ((inputCause === null)||(inputCause === '')) { //If the cause is not selected then order by rating
          this.setState({selectedPortSortOrder: "rating desc"});
          this.setState({selectedSortOrder: "Rating"});
          tempSortString = "rating desc";
        }
        else {
          tempSortString = "relevance desc";
        }

        this.setState({ refinedSortOptions: sortOptObjs });

        //Fetch all the charities based on the region and cause
        fetch(
          `${targetProtocol}://${targetHost}/matchingcharitiesadvanced?username=${apiUsername}&password=${apiPassword}&searchtext=${inputSearchText}&locationgranularity=${inputLocationGranularity}&region=${inputRegion}&location=${inputLocation}&ukregion=${inputUKRegion}&localarea=${inputLocalArea}&londonborough=${inputLondonBorough}&cause=${inputCause}&secondarycause=${inputSecondaryCause}&volmin=${inputVolMin}&volmax=${inputVolMax}&empmin=${inputEmpMin}&empmax=${inputEmpMax}&boutique=${inputBoutique}&giftaid=${inputGiftAid}&sortorder=${tempSortString}`
        )
          .then(res => {
            return res.json();
          })
          .then(data => {
            let eligibleCharitiesFromApi = data.map((eligibleCharity) => {
              return { charityno: eligibleCharity.charno, charityweb: eligibleCharity.charweb, charityname: eligibleCharity.charname,
                      income: eligibleCharity.income, employees: eligibleCharity.employees, volunteers: eligibleCharity.volunteers,
                      nolocations: eligibleCharity.nolocations, nocauses: eligibleCharity.nocauses, noregions: eligibleCharity.noregions,
                      income_employees: eligibleCharity.income_employees, income_volunteers: eligibleCharity.income_volunteers,
                      num_yrs_finhist: eligibleCharity.num_yrs_finhist, causes_mnemonic : eligibleCharity.causes_mnemonic,
                      pc_restrict: eligibleCharity.pc_restrict, pc_giving : eligibleCharity.pc_giving, pc_income_growth: eligibleCharity.pc_income_growth,
                      pc_activities_growth: eligibleCharity.pc_activities_growth, list_regions: eligibleCharity.list_regions,
                      pc_giving_growth: eligibleCharity.pc_giving_growth, pc_safety: eligibleCharity.pc_safety, last_submitted: eligibleCharity.last_submitted,
                      reliability: eligibleCharity.reliability, performance: eligibleCharity.performance, pc_giving_3yr: eligibleCharity.pc_giving_3yr,
                      rating: eligibleCharity.rating, relevance: eligibleCharity.relevance,
              };  //Sets up locationsFromApi as an object with value and display attributes
            }); 
            this.setState({ 
              eligibleCharities: eligibleCharitiesFromApi
            });
            this.setState({searchResultsPending: false});
          })
          .catch(error => {
            console.log(error);
          });
      }

    } 

  }

  handleSortChange(event) {     

    //Just sorted is set to true - gets sent to pagination to make sure it starts from 1 again
    //this.setState({ 
    //  justSorted: true
    //});

    //We want the rendering to wait for the results to come back
    this.setState({searchResultsPending: true});

    if (this.state.searchBasic === true) {
      fetch(
        `${targetProtocol}://${targetHost}/matchingcharitiesbasic?username=${apiUsername}&password=${apiPassword}&region=${this.state.searchRegion}&cause=${this.state.searchCause}&sortorder=${event.value}`
      )
        .then(res => {
          return res.json();
        })
        .then(data => {
          let eligibleCharitiesFromApi = data.map((eligibleCharity) => {
            return { charityno: eligibleCharity.charno, charityweb: eligibleCharity.charweb, charityname: eligibleCharity.charname,
              income: eligibleCharity.income, employees: eligibleCharity.employees, volunteers: eligibleCharity.volunteers,
              nolocations: eligibleCharity.nolocations, nocauses: eligibleCharity.nocauses, noregions: eligibleCharity.noregions,
              income_employees: eligibleCharity.income_employees, income_volunteers: eligibleCharity.income_volunteers,
              num_yrs_finhist: eligibleCharity.num_yrs_finhist, causes_mnemonic : eligibleCharity.causes_mnemonic,
              pc_restrict: eligibleCharity.pc_restrict, pc_giving : eligibleCharity.pc_giving, pc_income_growth: eligibleCharity.pc_income_growth,
              pc_activities_growth: eligibleCharity.pc_activities_growth, list_regions: eligibleCharity.list_regions,
              pc_giving_growth: eligibleCharity.pc_giving_growth, pc_safety: eligibleCharity.pc_safety, last_submitted: eligibleCharity.last_submitted,
              reliability: eligibleCharity.reliability, performance: eligibleCharity.performance, pc_giving_3yr: eligibleCharity.pc_giving_3yr,
              rating: eligibleCharity.rating, relevance: eligibleCharity.relevance,
    };  //Sets up locationsFromApi as an object with value and display attributes
          }); 
            let currentPageTemp = this.state.currentPage;
            let offset = (currentPageTemp - 1) * pageLimitRows;
            let currentCharitiesTemp = eligibleCharitiesFromApi.slice(offset, offset + pageLimitRows);
            this.setState({ 
              eligibleCharities: eligibleCharitiesFromApi,
              currentCharities: currentCharitiesTemp,
              searchResultsPending: false,
              selectedSortOrder: event.label
            });
        })
        .catch(error => {
          console.log(error);
        });
      }
      else {
        fetch( 
          `${targetProtocol}://${targetHost}/matchingcharitiesadvanced?username=${apiUsername}&password=${apiPassword}&searchtext=${this.state.searchInputText}&locationgranularity=${this.state.searchLocationGranularity}&region=${this.state.searchRegion}&location=${this.state.searchLocation}&ukregion=${this.state.searchUKRegion}&localarea=${this.state.searchLocalArea}&londonborough=${this.state.searchLondonBorough}&cause=${this.state.searchCause}&secondarycause=${this.state.searchSecondaryCause}&volmin=${this.state.searchVolMin}&volmax=${this.state.searchVolMax}&empmin=${this.state.searchEmpMin}&empmax=${this.state.searchEmpMax}&boutique=${this.state.searchBoutique}&giftaid=${this.state.searchGiftAid}&sortorder=${event.value}`
        )
          .then(res => {
            return res.json();
          })
          .then(data => {
            let eligibleCharitiesFromApi = data.map((eligibleCharity) => {
              return { charityno: eligibleCharity.charno, charityweb: eligibleCharity.charweb, charityname: eligibleCharity.charname,
                      income: eligibleCharity.income, employees: eligibleCharity.employees, volunteers: eligibleCharity.volunteers,
                      nolocations: eligibleCharity.nolocations, nocauses: eligibleCharity.nocauses, noregions: eligibleCharity.noregions,
                      income_employees: eligibleCharity.income_employees, income_volunteers: eligibleCharity.income_volunteers,
                      num_yrs_finhist: eligibleCharity.num_yrs_finhist, causes_mnemonic : eligibleCharity.causes_mnemonic,
                      pc_restrict: eligibleCharity.pc_restrict, pc_giving : eligibleCharity.pc_giving, pc_income_growth: eligibleCharity.pc_income_growth,
                      pc_activities_growth: eligibleCharity.pc_activities_growth, list_regions: eligibleCharity.list_regions,
                      pc_giving_growth: eligibleCharity.pc_giving_growth, pc_safety: eligibleCharity.pc_safety, last_submitted: eligibleCharity.last_submitted,
                      reliability: eligibleCharity.reliability, performance: eligibleCharity.performance, pc_giving_3yr: eligibleCharity.pc_giving_3yr,
                      rating: eligibleCharity.rating, relevance: eligibleCharity.relevance,
              };  //Sets up locationsFromApi as an object with value and display attributes
            }); 
            let currentPageTemp = this.state.currentPage;
            let offset = (currentPageTemp - 1) * pageLimitRows;
            let currentCharitiesTemp = eligibleCharitiesFromApi.slice(offset, offset + pageLimitRows);
            this.setState({ 
              eligibleCharities: eligibleCharitiesFromApi,
              currentCharities: currentCharitiesTemp,
              searchResultsPending: false,
              selectedSortOrder: event.label
            });
          })
          .catch(error => {
            console.log(error);
          });
      }

  }

  handleMinPerformanceSlider(event) {     
    this.setState({minPerformance: event.value}); 
  }

  handleMinReliabilitySlider(event) {     
    this.setState({minReliability: event.value}); 
  }

  handlePortTopXChange(event) {     
    this.setState({selectedPortTopX: event.target.value}); 
  }

  handlePortSortOrderChange(event) {    
    this.setState({selectedPortSortOrder: event.target.value});  
  }

  portButtonClicked () {
    
    this.setState({redirectOK: true});

  }

  onPageChanged = data => {

    const { eligibleCharities } = this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentCharities = eligibleCharities.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentCharities, totalPages });

    if (this.state.firstTime == true) {
      this.setState({firstTime: false});
    }
    else {
      if (isMobile) {
        window.scrollTo(0, 400);
      }
      else {
        window.scrollTo(0, 600);
      }
    }
  }

  render () {

    const animalsTooltip = props => (<Tooltip {...props}>Animals and Welfare</Tooltip>);
    const artsTooltip = props => (<Tooltip {...props}>Arts and Science</Tooltip>);
    const childrensTooltip = props => (<Tooltip {...props}>Childrens Welfare</Tooltip>);
    const communityTooltip = props => (<Tooltip {...props}>Community Development</Tooltip>);
    const disabilitiesTooltip = props => (<Tooltip {...props}>Disabilities</Tooltip>);
    const educationTooltip = props => (<Tooltip {...props}>Education</Tooltip>);
    const elderlyTooltip = props => (<Tooltip {...props}>Elderly</Tooltip>);
    const environmentTooltip = props => (<Tooltip {...props}>Environment and Conservation</Tooltip>);
    const ethnicTooltip = props => (<Tooltip {...props}>Ethnic</Tooltip>);
    const generalTooltip = props => (<Tooltip {...props}>General</Tooltip>);
    const homelessTooltip = props => (<Tooltip {...props}>Homelessness</Tooltip>);
    const humanTooltip = props => (<Tooltip {...props}>Human Rights and Diversity</Tooltip>);
    const militaryTooltip = props => (<Tooltip {...props}>Military/Emergency Services</Tooltip>);
    const overseasTooltip = props => (<Tooltip {...props}>Overseas Aid/Famine Relief</Tooltip>);
    const physicalTooltip = props => (<Tooltip {...props}>Physical and Mental Health</Tooltip>);
    const povertyTooltip = props => (<Tooltip {...props}>Poverty</Tooltip>);
    const recreationTooltip = props => (<Tooltip {...props}>Recreation and Wellness</Tooltip>);
    const religionTooltip = props => (<Tooltip {...props}>Religion</Tooltip>);
    const sportsTooltip = props => (<Tooltip {...props}>Sports</Tooltip>);
    
    var buttonEmbed;
    const { eligibleCharities, currentCharities, currentPage, totalPages } = this.state;
    const totalCharities = eligibleCharities.length;

    if (this.state.redirectOK === false) {
      buttonEmbed = 
      <button onClick={this.portButtonClicked}>
        Go!
      </button>
    }
    else {
      buttonEmbed = <Redirect push to={{
        pathname: "/ognportfolioresults",
        state: {
          inputSearchText: this.state.searchInputText,
          inputCause: this.state.searchCause,
          inputSecondaryCause: this.state.searchSecondaryCause,
          inputUKRegion: this.state.searchUKRegion,
          inputLondonBorough: this.state.searchLondonBorough,
          inputLocalArea: this.state.searchLocalArea,
          inputRegion: this.state.searchRegion,
          inputLocation: this.state.searchLocation,
          inputMinPerformance: this.state.minPerformance,
          inputMinReliability: this.state.minReliability,
          inputTopX: this.state.selectedPortTopX,
          inputSortOrder: this.state.selectedPortSortOrder,
          inputBasicSearch: this.state.searchBasic,
          inputLocationGranularity: this.state.searchLocationGranularity,
          inputBoutique: this.state.searchBoutique,
          inputGiftAid: this.state.searchGiftAid
        } 
        }}
        >
      </Redirect>
    }

    //If we have this code then if charities are equal to 0, it renders no output
    //if (this.state.searchResultsPending === true) return null;

    //if (this.state.searchResultsPending === true) return (<img src={RetrievingResults} className="img-fluid" alt="banner" />);
    if (this.state.searchResultsPending === true) return (<div className="loadingimage"><img style={{animation: `spin ${this.state.speed}s linear infinite`}} src={RetrievingResults} width="150px" alt="img"/></div>);

    //Redirect to the homepage if user didnt come through expected channel
    if (this.state.organicEntry === false) return (<Redirect to={{
      pathname: "/"}}>
    </Redirect>);

    //Return this is Charities returned is equal to zero
    if (totalCharities === 0) return (<Fragment>
        <Container>
          <div className="search-result-wrapper">
            <div className="banner">
              <img src={Banner} className="img-fluid" alt="banner" />
            </div>
            <hr className="hr-search"/>
            <Row>
            <Col md={6}>  <br></br><br></br>
            <div className="d-flex flex-row align-items-center">
                <h2 className="h2covert">
                  <strong>{totalCharities}</strong> {totalCharities===1?'Charity':'Charities'} Found
                </h2>
            </div>
            <br></br><br></br>
            </Col>
            </Row>
          </div>{" "}
            <hr className="hr-search"/>
        </Container>
        <div className="mini-logo-container">
          <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
        </div>
        <Footer />
      </Fragment>
    );

  return (<Fragment>
      <Container>
        <div className="search-result-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-search"/>
          <Row>
          <Col md={6}>  
          <div className="d-flex flex-row align-items-center">
              <h2 className="h2covert">
                <strong>{totalCharities}</strong> Charities
              </h2>
              { currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{ currentPage }</span> / <span className="font-weight-bold">{ totalPages }</span>
                </span>
              ) }
            </div>
          </Col>
            <Col md={6}>
          <div className="sort-order">
                  <Select
                    onChange={this.handleSortChange.bind(this)}
                    options={this.state.refinedSortOptions}
                    placeholder={this.state.selectedSortOrder}
                  />
                  </div>
          </Col>
          </Row>
          <div className="search-result-content" id='topofpage'>
            {currentCharities.map((item, index) => {
              return (
                <Fragment>
                  <div className="result" key={index}>
                    <div className="charity-details">
                    <Row>
                      <Col md={6}>
                      <h5>{item.charityname} ({item.charityno})</h5>
                            <br></br>
                            { parseInt(item.rating,10) > 0 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 20 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 40 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 60 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                            { parseInt(item.rating,10) > 80 ? <img src={StarRating} alt="logo" className="img-fluid" width="50" /> : "" }
                      <br></br><br></br>
                      <h6><a href={item.charityweb} target="_blank">Go to Website</a></h6><br></br>
                      <div>
                      <Fragment>
        { item.causes_mnemonic.includes('a') ? <OverlayTrigger placement="top" overlay={animalsTooltip}><img src={AnimalsIcon} alt="logo" className="img-fluid" width="50" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('b') === true ? <OverlayTrigger placement="top" overlay={artsTooltip}><img src={ArtsIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('c') === true ? <OverlayTrigger placement="top" overlay={childrensTooltip}><img src={ChildrensIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('d') === true ? <OverlayTrigger placement="top" overlay={communityTooltip}><img src={CommunityIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('e') === true ? <OverlayTrigger placement="top" overlay={disabilitiesTooltip}><img src={DisabilitiesIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('f') === true ? <OverlayTrigger placement="top" overlay={educationTooltip}><img src={EducationIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('g') === true ? <OverlayTrigger placement="top" overlay={elderlyTooltip}><img src={ElderlyIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('h') === true ? <OverlayTrigger placement="top" overlay={environmentTooltip}><img src={EnvironmentIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('i') === true ? <OverlayTrigger placement="top" overlay={ethnicTooltip}><img src={EthnicIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('j') === true ? <OverlayTrigger placement="top" overlay={generalTooltip}><img src={GeneralIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('k') === true ? <OverlayTrigger placement="top" overlay={homelessTooltip}><img src={HomelessIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('l') === true ? <OverlayTrigger placement="top" overlay={humanTooltip}><img src={HumanIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('m') === true ? <OverlayTrigger placement="top" overlay={militaryTooltip}><img src={MilitaryIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('n') === true ? <OverlayTrigger placement="top" overlay={overseasTooltip}><img src={OverseasIcon} alt="logo" className="img-fluid" width="50" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('o') === true ? <OverlayTrigger placement="top" overlay={physicalTooltip}><img src={PhysicalIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('p') === true ? <OverlayTrigger placement="top" overlay={povertyTooltip}><img src={PovertyIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('q') === true ? <OverlayTrigger placement="top" overlay={recreationTooltip}><img src={RecreationIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('r') === true ? <OverlayTrigger placement="top" overlay={religionTooltip}><img src={ReligionIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
        { item.causes_mnemonic.includes('s') === true ? <OverlayTrigger placement="top" overlay={sportsTooltip}><img src={SportsIcon} alt="logo" className="img-fluid" width="40" /></OverlayTrigger> : "" }
<br></br><br></br>
      </Fragment>
                      </div>
                      </Col>
                      <Col md={6}>
                      <h6 className="pchange">Stability: <b>{numberWithCommas(item.reliability)}% </b></h6>
                            <h6 className="pchange">Performance: <b>{numberWithCommas(item.performance)}% </b></h6>
                            <p><a href="/ogn-ratings-overview">How are these calculated?</a></p><br></br>
                      <p>Income: <b>£{numberWithCommas(item.income)}</b></p>
                      <p>Regions: <b>{item.list_regions}</b></p>
                      <br></br>
                      </Col>
                      </Row>
                      <div className="details-btn">
                      <Link to={`/charity-detail/${item.charityno}` } 
                      >
                      <button type="button" className="details-btn">Read More</button>
                      </Link>
                      </div>
                    </div>
                  </div>
                  {index === 2 &&
                  <div className="result">
                    <div className="charity-details">
                      <h5>
                      <HashLink smooth to='/search-result/#section1' >WHY NOT CREATE YOUR CHARITY PORTFOLIO?</HashLink>
                      </h5>
                    </div>
                  </div>
                  }
                </Fragment>
                
              );
            })}
          </div>
          <div className="d-flex flex-row py-4 align-items-center">
              <Pagination totalRecords={totalCharities} pageLimit={pageLimitRows} pageNeighbours={1} onPageChanged={this.onPageChanged} />
            </div>
          <h2 className="h2covert" id={'section1'}>
                  Why not create your charity portfolio?
                </h2>
                <br></br>
          <fieldset className="fieldset-adv">
                <Row>
                  <Col md={3}>
                    <p className="paragraphspacer">Performance (%)</p>
                  </Col>
                  <Col md={3}>
                    <div className="select-dropdown">
                      <RangeSlider
                        value={this.state.minPerformance}
                        onChange={e => this.setState({minPerformance: e.target.value})}
                        step={5}
                        tooltip="on"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <p className="paragraphspacer">Reliability (%)</p> 
                  </Col>
                  <Col md={3}>
                    <div className="select-dropdown"><RangeSlider
                        value={this.state.minReliability}
                        onChange={e => this.setState({minReliability: e.target.value})}
                        step={5}
                        tooltip="on"
                      />
                    </div>
                  </Col>
                  </Row>
                  <Row>
                  <Col md={3}>
                    <p className="paragraphspacer">No. Charities in Portfolio</p> 
                  </Col>
                  <Col md={3}>
                    <form className="form-choice">
                        <label className="label-choice">
                          <input type="radio" value="3" name="porttopx" onChange={this.handlePortTopXChange} checked={this.state.selectedPortTopX === '3'}/>
                          <span>3</span>
                        </label>
                        <label className="label-choice">
                          <input type="radio" value="5" name="porttopx" onChange={this.handlePortTopXChange} checked={this.state.selectedPortTopX === '5'}/>
                          <span>5</span>
                        </label>
                        <label className="label-choice">
                          <input type="radio" value="10" name="porttopx" onChange={this.handlePortTopXChange} checked={this.state.selectedPortTopX === '10'}/>
                          <span>10</span>
                        </label>
                      </form>
                  </Col>
                  <Col md={3}>
                    <p className="paragraphspacer">Focus</p> 
                  </Col>
                  <Col md={3}>
                    <form className="form-choice">
                        <label className="label-choice">
                          <input type="radio" value="reliability desc, performance desc" name="portsortorder" onChange={this.handlePortSortOrderChange} checked={this.state.selectedPortSortOrder === 'reliability desc, performance desc'}/>
                          <span>Reliability</span>
                        </label>
                      </form>
                      <form className="form-choice">
                      <label className="label-choice">
                          <input type="radio" value="performance desc, reliability desc" name="portsortorder" onChange={this.handlePortSortOrderChange} checked={this.state.selectedPortSortOrder === 'performance desc, reliability desc'}/>
                          <span>Performance</span>
                        </label>
                        </form>
                  </Col>
                </Row>
                </fieldset><br></br>
                <Row>
                  <Col md={12}>
                    <div className="details-btn">
                      {buttonEmbed}
                    </div>
                  </Col>
                </Row>
        </div>{" "}
          <hr className="hr-search"/>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );

};
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default SearchResult;
