import React, { Fragment } from "react";

// import style sheet
import "./newsstory.scss";

// import bootstrap components
import Container from "react-bootstrap/Container";

// import images
import Banner from "../../assets/images/ognsearchbanner.jpg";
import Footer from "../Footer";
import MiniLogo from "../../assets/images/logo-mini.jpg";


class OGNPortfolioNews extends React.Component {
  render() {
  return (
    <Fragment>
      <Container>
        <div className="news-story-wrapper">
          <div className="banner">
            <img src={Banner} className="img-fluid" alt="banner" />
          </div>
          <hr className="hr-news-story"/>
          <div className="news-story-content">
            <h1>OGN Portfolio Launched</h1><br></br><br></br>
            <h5>Tuesday 22nd June 2021</h5><br></br><br></br>
            <p>Since<b> Open Giving Now </b>was conceived, our ambition has always been to match donors with the perfect 
              charity. 
              We started off by offering flexible and intelligent searching of the data, and before 
              long we were showing key performance indicators, wrapping them up into ratings for reliability and performance. 
              But we still felt that we could make things more donor friendly.
              <br></br><br></br>
              Donors often say to us that whilst they would like to donate a 
              greater amount, they find that one of the biggest challenges they face is to find a portfolio of charities. 
              Partly to de-risk the investment, but mainly to make it fairer, spreading it 
              evenly across a few select charities who might all be serving the cause well in their own way. 
              <br></br><br></br>              
              Today we launch the<b> Open Giving Now </b>Portfolio (OGN Portfolio), a tool that helps you make a 
              measured investment in a portfolio of charities with ease. 
              Retaining the ability to zero in on the causes, locations, and charity types that matter to you through the 
              search, we have also added the ability to dictate the key characteristics of your portfolio, such 
              as the number of charities, or whether you prefer to invest in reliable or performant charities.
              <br></br><br></br>
              With these key characteristics entered, allied with the power of the advanced search, you're just 
              a click away from finding your perfect charity portfolio and making even more of an impact. 
              Why don't you take a look <a href="/ognportfolio">here</a>?
              <br></br><br></br>
              
            </p>
          </div>
      <hr className="hr-news-story"/>
        </div>
      </Container>
      <div className="mini-logo-container">
        <img src={MiniLogo} alt="logo" className="img-fluid" width="150" />
      </div>
      <Footer />
    </Fragment>
  );
};
}

export default OGNPortfolioNews;
