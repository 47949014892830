import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// import style sheet
import "./navbar.scss";

// import bootstrap components
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

// import images
import Logo from "../../assets/images/logo.jpg";
import Search from "../../assets/images/search.png";

//const NavbarMain = () => {
class NavbarMain extends React.Component {
  render() {
  return (
    <Fragment>
      <div className="navbar-container">
        <Container>
          <Navbar expand="md">
            <Navbar.Brand href="/">
              <img src={Logo} alt="logo" className="img-fluid" width="400" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="/what-we-do">what we do</Nav.Link>
                <Nav.Link href="/smartsearch">find your charity </Nav.Link>
                <Nav.Link href="/about-us">about us </Nav.Link>
                <Nav.Link href="/news-and-features">
                  news {"&"} features{" "}
                </Nav.Link>
              </Nav>
              <div className="navbar-search-btn">
                <Link to={{
                    pathname: "/"
                      }}
                    >
                <button>
                  <img src={Search} alt="search-icon" />
                </button>
                </Link>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </Fragment>
  );
};
}

export default NavbarMain;
